<section id="forgot-password">
	<div class="container-fluid gradient-red-pink">
		<div class="row full-height-vh">
			<div class="col-12 d-flex align-items-center justify-content-center">
				<div class="card bg-blue-grey bg-darken-3 px-4">
					<div class="card-header">
						<div class="card-image text-center">
							<i class="icon-key font-large-5 blue-grey lighten-4"></i>
						</div>
					</div>
					<div class="card-body">
						<div class="card-block">
							<div class="text-center">
								<h4 class="text-uppercase text-bold-400 white">Recover Password</h4>
							</div>
							<form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
								<label for="">Email</label>
								<input type="text" class="form-control" name="email" [(ngModel)]="model.email"
									#email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required
									email placeholder="Your Email Address" />
								<div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
									<div *ngIf="email.errors.required">Email is required</div>
									<div *ngIf="email.errors.email">Email must be a valid email address</div>
								</div>
								<hr>
								<label for="">New Password</label>
								<div class="input-group">
									<input type="password" class="form-control" name="password"
										[(ngModel)]="model.password" #password="ngModel"
										[ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required
										id="password" />
									<div class="input-group-append">
										<span class="input-group-text showhidepasswd"
											(click)="showHidePasswd('password');showN=!showN">
											<i class="fas fa-eye" *ngIf="!showN"></i>
											<i class="fas fa-eye-slash" *ngIf="showN"></i>
										</span>
									</div>
								</div>
								<div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
									<div *ngIf="password.errors.required">Password is required</div>
								</div>
								<div class="form-group pt-2">
									<div class="text-center mt-3">
										<button type="submit"
											class="btn btn-danger btn-raised btn-block">Submit</button>
									</div>
								</div>
							</form>
						</div>
						<div class="card-footer bg-blue-grey bg-darken-3">
							<div class="text-center white"><a routerLink="/auth/login">Login</a></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>