import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RecallToothStatus, RecallToothStatusDetail, PatientRecall } from '@app/shared/models';
import { Patient } from '@app/core/patients/patient';
import { PrintService } from '@app/shared/services/helpers/print.service';
import { ToothStatusService } from '@app/shared/services/mstfapi/tooth-status.service';
import { PeriodontalScreeningService } from '@app/shared/services/mstfapi/periodontal-screening.service';
import { RecallOcclusionService } from '@app/shared/services/mstfapi/recall-occlusion.service';
import { RecallApplianceService } from '@app/shared/services/mstfapi/recall-appliance.service';
import { RecallTmdService } from '@app/shared/services/mstfapi/recall-tmd.service';
import { forkJoin, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { FilesService } from '@app/shared/services/api/files.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-print-recall',
  templateUrl: './print-recall.component.html',
  styleUrls: ['./print-recall.component.scss']
})
export class PrintRecallComponent implements OnInit, OnDestroy {
  base_url: string = environment.apiUrlV2;

  pageTitle: string = 'New Patient Recall';
  submitted = false;
  formData: any = {};
  formPoat: any = {
    periodontal: [],
    occlusion: [],
    appliance: [],
    tmd: []
  };
  rawStatusDetail: RecallToothStatusDetail = {
    status: 0,
    color: '#FFF'
  };
  rawStatus: RecallToothStatus;
  intraOral: RecallToothStatus[] = [];
  intraOralForm: any = {
    right_0: [51, 52, 53, 54, 55],
    right_1: [11, 12, 13, 14, 15, 16, 17, 18],
    right_2: [41, 42, 43, 44, 45, 46, 47, 48],
    right_3: [81, 82, 83, 84, 85],
    left_0: [61, 62, 63, 64, 65],
    left_1: [21, 22, 23, 24, 25, 26, 27, 28],
    left_2: [31, 32, 33, 34, 35, 36, 37, 38],
    left_3: [71, 72, 73, 74, 75]
  };
  masterfile: any = {
    periodontal: [],
    occlusion: [],
    appliance: [],
    tmd: []
  };

  @Input() precall: PatientRecall;

  recall: PatientRecall;
  patient: Patient;

  printSub: Subscription;
  patientSub: Subscription;

  hasIntraOralExam: boolean = true;
  recallFiles: any[] = [];
  legendTexts: any[] = [];

  loading: boolean = true;
  constructor(
    public printSvc: PrintService,
    public toothStatusSvc: ToothStatusService,
    private periondontalSvc: PeriodontalScreeningService,
    private occlusionSvc: RecallOcclusionService,
    private applianceSvc: RecallApplianceService,
    private tmdSvc: RecallTmdService,
    private fileService: FilesService
  ) {}

  ngOnInit() {
    this.toothStatusSvc.fetchToothStatuses();
    this.setBlankIntraOral();

    this.patientSub = this.printSvc.activePatient.subscribe((patient: any) => {
      this.patient = patient;
    });

    this.periondontalSvc.getAllRecord().subscribe((rows: any) => {
      this.masterfile.periodontal = rows?.data;
      this.setPoat('periodontal');
    });
    this.occlusionSvc.getAllRecord().subscribe((data: any) => {
      this.masterfile.occlusion = data.data;
    });
    this.applianceSvc.getAllRecords().subscribe((data) => {
      if (data && data.data) {
        this.masterfile.appliance = data.data;
      }
    });

    this.tmdSvc.getAllRecord().subscribe((rows: any) => {
      this.masterfile.tmd = rows?.data;
    });

    this.printSub = this.printSvc.toPrintData.subscribe((printData: any) => {
      if (printData.recall) {

        this.setRecall(printData.recall);

        this.recallFiles = printData.recall.files;
        if (!this.recallFiles || this.recallFiles.length <= 0) {
          this.printSvc.contentReady = true;
          this.loading = false;
        } else {
          this.loadImages();
        }
      } else if (this.precall) {
        this.setRecall(this.precall);
        this.loading = false;
        const parseData = this.precall.files;
        this.recallFiles = parseData;
       
      }
    });
  }

  loadImages() {
    let totalImages: number = this.recallFiles.length;
    this.loading = true;

    this.recallFiles.forEach((file: any) => {
      var img = new Image();
      img.onload = (e) => {
        totalImages -= 1;
        if (totalImages <= 0) {
          this.printSvc.contentReady = true;
        }
      };
      img.onerror = (e) => {
        totalImages -= 1;
        if (totalImages <= 0) {
          this.printSvc.contentReady = true;
        }
      };
      img.src = this.base_url + '/file?f=' + file.filename;
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.printSub.unsubscribe();
    this.patientSub.unsubscribe();
  }

  setRecall(recall: PatientRecall) {
    this.recall = recall;
    let intraOral = JSON.parse(this.recall.intraoralExam);

    if (intraOral && intraOral.length == 0) {
      this.hasIntraOralExam = false;
    }
    intraOral.forEach((row) => {
      if (row.data.legendsObj && row.data.legendsObj.length > 0) {
        row.data.legendsObj.forEach((obj) => {
          if (obj.cd && obj.t) {
            this.legendTexts.push({ c: obj.cd, t: obj.t });
          }
        });
      }
      row.data.touched = true;
      this.intraOral[row.pos] = row.data;
    });
    this.setPoat();
  }

  setPoat(key?: string) {
    if (!this.recall || !this.recall.id) {
      return;
    }
    let rkey = key;
    if (key == 'periodontal') {
      rkey = 'periodontalScreening';
    }
    if (key) {
      let records = this.recall[rkey].split(',');
      if (this.masterfile[key] && this.masterfile[key].length > 0) {
        this.masterfile[key].forEach((row) => {
          if (row.id && records.indexOf(row.id.toString()) >= 0) {
            this.formPoat[key].push(row.name);
          }
        });
      }
    } else {
      let keys = ['periodontal', 'occlusion', 'appliance', 'tmd'];
      keys.forEach((key) => {
        this.setPoat(key);
      });
    }
  }

  setBlankIntraOral() {
    Object.keys(this.intraOralForm).forEach((key) => {
      this.intraOralForm[key].forEach((idx) => {
        let rawStatus: RecallToothStatus = {
          cen: this.copy(this.rawStatusDetail),
          top: this.copy(this.rawStatusDetail),
          lef: this.copy(this.rawStatusDetail),
          rig: this.copy(this.rawStatusDetail),
          bot: this.copy(this.rawStatusDetail),
          legendsStr: [],
          legendsObj: [],
          legends: {}
        };
        this.intraOral[idx] = rawStatus; //this.copy(rawStatus);
      });
    });
  }

  copy(source) {
    return JSON.parse(JSON.stringify(source));
  }
}
