import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  formData: UntypedFormGroup;
  submitted: boolean = false;

  subscriptionPlans: any[] = [];
  showForm: boolean = true;

  constructor(private fb: UntypedFormBuilder, private router: Router, private route: ActivatedRoute, private auth: AuthService) {}

  ngOnInit() {
    this.auth.getPlans().subscribe((resp: any) => {
      if (resp) {
        resp.forEach((plan) => {
          this.subscriptionPlans.push({
            id: plan.id,
            name: this.getName(plan.name),
            desc: this.getName(plan.descriptions),
            price: plan.price,
            interval: plan.invoice_interval,
            period: plan.invoice_period
          });
        });
      }
    });

    this.formData = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', Validators.required],
      license_no: ['', Validators.required],
      ptr_no: ['', Validators.required],

      plan_id: [null],

      clinic_name: ['', Validators.required],
      specialization: ['General Dentistry', Validators.required],
      contact: ['', Validators.required],
      address: ['', Validators.required]
    });
  }

  next() {
    if (this.formData.invalid) {
      swal('Please fill all fields correctly.', '', 'error');
      return;
    }
    this.showForm = false;
  }

  getName(text) {
    let name: any = '';
    try {
      const strr = JSON.parse(text);
      name = strr.en;
    } catch (e) {
      name = text;
    }
    return name;
  }

  onSubmit() {
    if (this.submitted) {
      return;
    }
    if (this.formData.invalid) {
      swal('', 'Please fill all fields correctly.', 'error');
      return;
    }
    if (!this.formData.value.plan_id || this.formData.value.plan_id <= 0) {
      swal('', 'Please select the schedule you want to pay your subscription.', 'error');
      return;
    }

    this.submitted = true;
    this.auth.register(this.formData.value).subscribe(
      (data) => {
        swal('Welcome to MyMedsPH Dental App.', 'You can now login using your registered email address and the password sent to your email and mobile number.', 'success');
        this.backToLogin();
      },
      (error) => {
        this.submitted = false;
        if (error.error && error.error.msg) {
          swal('Registration Error.', error.error.msg, 'warning');
          return;
        }
        swal('Registration Error.', 'Please message or call support at 0927.630.3846 or 0917.770.9504.', 'warning');
      }
    );
  }

  backToLogin() {
    this.router.navigate(['login'], { relativeTo: this.route.parent });
  }
}
