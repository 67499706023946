import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./login/login.component";
// import { AuthGuardService } from '@app/shared/auth/auth-guard.service';
import { ForgotpasswdComponent } from "./forgotpasswd/forgotpasswd.component";
import { RecoverComponent } from "./recover/recover.component";
import { SignupComponent } from "./signup/signup.component";

const routes: Routes = [
  {
    path: "auth",
    children: [
      {
        path: "login",
        component: LoginComponent
      },
      {
        path: "forgotpassword",
        component: ForgotpasswdComponent
      },
      {
        path: "recover",
        component: RecoverComponent
      },
      {
        path: "signup",
        component: SignupComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
