import { Injectable } from '@angular/core';

import * as JWT from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class JwtHelperService {

    constructor() { }

    isTokenExpired(token: any, offsetSeconds? : number) {
        if (token === null || token === '') {
            return true;
        }
        var date = this.getTokenExpirationDate(token);
        offsetSeconds = offsetSeconds || 0;
        if (date === null) {
            return true;
        }
        return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
    }
    
    getTokenExpirationDate(token: any) {
        var decoded :any = this.decodeToken(token);
        if (!decoded.hasOwnProperty('exp')) {
            return null;
        }
        var date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    decodeToken(token: any) {
        return JWT(token);
    }

}
