<div class="assessment">

    <h3 class="text-center text-uppercase mt-4 mb-4">
        {{assessment.consentName}}
    </h3>

    <div class="form-group">
        <div [innerHtml]="assessJSONHTML"></div>
    </div>

    <div class="form-group patient-sig">
        <div class="text-center">
            <div class="signature"> <img src="{{ signatureUrl }}" /> </div>
            <h1 class="namesig">{{ assessJSON.signatory}}</h1>
            <div> <label class="control-label text-center text-uppercase">Patient/Guardian Signature</label> </div>
        </div>
    </div>

    <div class="form-group guardian-sig" *ngIf="assessJSON.signatory_staff && assessment.guardianSignatureUrl">
        <div class="text-center">
            <div class="guardian_signature">
                <img src="{{ guardianSignatureUrl }}" />
            </div>
            <h1 class="namesig">{{ assessJSON.signatory_staff }}</h1>
            <div> <label class="control-label text-center text-uppercase">Dental Staff Signature</label>
            </div>
        </div>
    </div>
</div>
