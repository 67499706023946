import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class ToothStatusService extends BaseService {

	constructor(
		http: HttpClient
	) {
		super(http);
		this.url =  environment.apiUrlV2 + '/masterfile/tooth-status';
		// this.url =  environment.mstfUrl + '/tooth_status';
		// this.getInitRecords().subscribe();
	}
	fetchToothStatuses() {
		this.refreshList();
	  }
}
