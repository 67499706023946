<div class="assessment">
    <h3 class="text-center mt-4 mb-1">SELF-ASSESSMENT FORM</h3>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th class="text-center">YES</th>
                <th class="text-center">NO</th>
                <th>Assessment</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of assessmentItems; let i=index">
                <td class="text-center">
                    <label *ngIf="assessment.assessment_json[item.name]=='1'">
                        YES
                    </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessment.assessment_json[item.name]=='0'">
                        NO
                    </label>
                </td>
                <td>
                    {{item.text}}
                </td>
            </tr>
        </tbody>
    </table>
    <div class="form-group form-inline">
        <label class="control-label">Temperature (&deg;C): </label>
        <span *ngIf="assessment && assessment.assessment_json.temp"> <b> {{assessment.assessment_json.temp}} </b> </span>
    </div>

    <h4 class="text-uppercase text-center mt-4 mb-1">Oath of Affirmation</h4>
    <p>
        I <b><u> {{ [patient.firstname, patient.middlename, patient.lastname].join(' ') }} </u></b>, do solemnly swear
        under
        oath states that my declaration in the Patient's Form are True and that I understand that should it be
        discovered to be false later on, I will be subjected to Criminal Prosecution and subject my estate for
        claims against it for damages.
    </p>

    <div class="text-center">
        <div class="signagure"> <img src="{{ signatureUrl }}" /> </div>
        <div> <label class="control-label text-center text-uppercase">Patient Name and Signature</label> </div>
    </div>

    <h4 class="text-center m-4"> <b> RA11332 Law on Reporting of Communicable Diseases </b> </h4>
    <p>
        <b>Section 9.d</b> "Non-cooperation of persons or entities that should report or respond to notifiable
        diseases or health events of public concern"
    </p>
    <p>
        <b>Section 10</b> "fine of 20,000.00 to 50,000.00 or be imprisoned for 1 to 6 months or both"
    </p>

    <div class="notary mt-4">
        SUBSCRIBE AND SWORN to before my Notary Public affiant exhibiting his/her government issued ID No.
        ___________________ issued on ___________________ issued at ____________________________________________. Done
        on the day of __________________, _____ in __________________________.
    </div>
</div>
