import { Injectable } from '@angular/core';
import {
  CACHED_DIGITAL_SIGNATURE_KEY,
  PRINT_HEADER_KEY,
  CACHED_PRINT_LOGO_KEY,
  USE_DIGITAL_SIGNATURE_KEY,
  DIGITAL_SIGNATURE_KEY,
  DEFAULT_HEADER_LOGO_WIDTH,
  CachedFileType,
  PRINT_HEADER_FILES_KEY,
  PRINT_HEADER_PER_CLINIC_KEY,
  CACHED_PRINT_HEADER_PER_CLINIC
} from '@app/shared/constants/settings.constants';
import { LocalStorageService } from 'angular-web-storage';
import { FilesService } from '../api/files.service';
import { SettingsService } from '../api/settings.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { CachedPrintLogo } from '@app/shared/models/system';
import { Observable, of, throwError } from 'rxjs';
import { EXPIRATION_FOR_CACHE } from '@app/shared/constants/Expiration.constants';
import { catchError, delay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  constructor(
    private storage: LocalStorageService,
    private setSvc: SettingsService,
    private fileService: FilesService,
    private authSvc: AuthService
  ) {}

  clearCache() {
    this.storage.remove(CACHED_PRINT_LOGO_KEY);
    this.storage.remove(CACHED_DIGITAL_SIGNATURE_KEY);
    this.clearPrintHeadersInStorage();
  }

  clearPrintHeadersInStorage(): void {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith(CACHED_PRINT_HEADER_PER_CLINIC)) {
        this.storage.remove(key);
      }
    }
  }

  async cacheData() {
    this.clearCache();
    await this.setSvc.getInitRecords({}).toPromise();
    this.cachePrintLogo();
    this.cacheDigitalSignature();
  }

  async cachePrintHeaderPerClinic(id?: number) {
    await this.setSvc.getInitRecords({}).toPromise();
    this.cacheLogoPerClinic(id);
  }

  getCachedPrintLogo(): string {
    return this.storage.get(CACHED_PRINT_LOGO_KEY);
  }

  getCachedSignature(): string {
    return this.storage.get(CACHED_DIGITAL_SIGNATURE_KEY);
  }
  getCachedPrintLogoPerClinic(): CachedPrintLogo {
    const clinicId = this.authSvc.getUserData('clinic_id');
    const key = CACHED_PRINT_HEADER_PER_CLINIC + '-' + clinicId;
    const value = this.storage.get(key);
    if (!value) {
      this.cachePrintHeaderPerClinic(clinicId);
    }
    return { key, value };
  }

  getCachedImage(filename: string): Observable<{ filename: string; uri: string }> {
    const cachedFile = this.storage.get(filename);
    if (cachedFile) {
      return of({ filename, uri: cachedFile });
    } else {
      return this.fileService.getFilenameDataUri(filename).pipe(
        delay(Math.floor(Math.random() * (1000 - 1 + 1)) + 1),
        tap((data) => {
          this.storage.set(data.filename, data.uri, EXPIRATION_FOR_CACHE, 'd');
        }),
        catchError((error) => {
          console.error('Error loading cached image:', error);
          return throwError(error);
        })
      );
    }
  }

  private cachePrintLogo() {
    let printHeaderFiles = this.setSvc.get(PRINT_HEADER_FILES_KEY);
    if (!printHeaderFiles) {
      this.setSvc.getInitRecords({}).subscribe((_) => {
        printHeaderFiles = this.setSvc.get(PRINT_HEADER_KEY);
        this.savePrintLogo(printHeaderFiles);
      });
    }
    this.savePrintLogoFile(printHeaderFiles);
  }

  private cacheDigitalSignature() {
    const useSignature: any = this.setSvc.get(USE_DIGITAL_SIGNATURE_KEY);
    if (!useSignature || parseInt(useSignature) === 0) {
      return;
    }
    let digitalSignature = this.setSvc.get(DIGITAL_SIGNATURE_KEY);
    if (!digitalSignature) {
      this.setSvc.getInitRecords({}).subscribe((_) => {
        digitalSignature = this.setSvc.get(DIGITAL_SIGNATURE_KEY);
        this.saveDigitalSignature(digitalSignature);
      });
    }
    this.saveDigitalSignature(digitalSignature);
  }

  private async saveDigitalSignature(signature?: string) {
    if (!signature || signature.trim().length === 0) {
      return;
    }
    const base64Signature: { filename: string } = await this.fileService
      .getPresignedFilenameDataUriV2(signature)
      .toPromise();

    if (base64Signature && base64Signature.filename) {
      this.storage.set(CACHED_DIGITAL_SIGNATURE_KEY, base64Signature);
    }
  }

  // old print header via print_header
  private async savePrintLogo(printHeader?: string) {
    if (!printHeader || printHeader.trim().length === 0) {
      return;
    }
    const printLogo = this.getBase64PrintLogo(printHeader);
    if (!printLogo) {
      return;
    }
    const base64Logo: CachedFileType = await this.fileService
      .getPresignedFilenameDataUriV2(printLogo.filename)
      .toPromise();
    if (!base64Logo || !base64Logo.filename) {
      return null;
    }
    base64Logo.width = printLogo.width;
    this.storage.set(CACHED_PRINT_LOGO_KEY, base64Logo);
  }

  // old print header via print_header
  private getBase64PrintLogo(printHeader: string): CachedFileType {
    const parser = new DOMParser();
    const doc = parser.parseFromString(printHeader, 'text/html');
    const img = doc.querySelector('img');
    if (!img) {
      return null;
    }
    const printLogo = img.getAttribute('src');
    if (!printLogo || printLogo.trim().length === 0) {
      return null;
    }
    const logoFile = printLogo.split('files/')[1].replace('"', '');
    const width = img.getAttribute('width');
    return {
      filename: logoFile,
      width: width ? parseInt(width) : DEFAULT_HEADER_LOGO_WIDTH
    };
  }

  // new print header via print_header_file
  private async savePrintLogoFile(printHeader?: string) {
    if (!printHeader || printHeader.trim().length === 0) {
      return;
    }
    const printLogo = this.getBase64PrintLogoDataUri(printHeader);
    if (!printLogo) {
      return;
    }
    const base64Logo: CachedFileType = await this.fileService
      .getPresignedFilenameDataUriV2(printLogo.filename)
      .toPromise();
    if (!base64Logo || !base64Logo.filename) {
      return null;
    }
    base64Logo.width = printLogo.width;
    this.storage.set(CACHED_PRINT_LOGO_KEY, base64Logo);
  }

  // new print header via print_header_file
  private getBase64PrintLogoDataUri(printHeader: string): CachedFileType {
    return {
      filename: printHeader,
      width: DEFAULT_HEADER_LOGO_WIDTH
    };
  }

  private cacheLogoPerClinic(clinicId?: number) {
    let clinic_id = clinicId || this.authSvc.getUserData('clinic_id');
    let getClinicPrintHeader: any;

    getClinicPrintHeader = this.setSvc.get(PRINT_HEADER_PER_CLINIC_KEY + '_' + clinic_id);
    if (!getClinicPrintHeader) {
      this.setSvc.getInitRecords({}).subscribe((_) => {
        getClinicPrintHeader = this.setSvc.get(PRINT_HEADER_PER_CLINIC_KEY + '_' + clinic_id);
        this.printHeaderPerClinicLogo(clinic_id, getClinicPrintHeader);
      });
    }
    this.printHeaderPerClinicLogo(clinic_id, getClinicPrintHeader);
  }

  private async printHeaderPerClinicLogo(clinicId?: number, printHeader?: string) {
    const key: string = CACHED_PRINT_HEADER_PER_CLINIC + '-' + clinicId;
    this.storage.set(key, printHeader);
  }
}
