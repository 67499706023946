import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss']
})
export class ControlMessagesComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() controlName: string;
  @Input() submitted: boolean;

  constructor() {}

  ngOnInit() {}
}
