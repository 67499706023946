import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/shared/auth/auth.service';
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { PrintService } from './shared/services/helpers/print.service';
import { LoadingService } from './shared/services/helpers/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading = this.loadingService.isLoading$;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public printSvc: PrintService,
    private loadingService: LoadingService
  ) {
    // this.initializeApp();
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.show();
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loadingService.hide();
      }
    });
  }

  initializeApp() {
    // this.auth.authenticationState.subscribe(state => {
    //     if (!state) {
    //         this.router.navigate(['auth/login']);
    //     }
    // });
  }

  logout() {
    this.auth.logout();
  }
}
