import { Component, OnInit, OnDestroy, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Patient } from '@app/shared/models';
import { FilesService } from '@app/shared/services/api/files.service';
import { PrintService } from '@app/shared/services/helpers/print.service';
import { environment } from '@env/environment';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'app-print-informed-consent',
    templateUrl: './print-informed-consent.component.html',
    styleUrls: ['./print-informed-consent.component.scss']
})
export class PrintInformedConsentComponent implements OnInit, OnDestroy {

    base_url: string = environment.url;
    patient: Patient;
    assessment: any;
    assessJSON: any;
    assessJSONHTML: SafeHtml;
    assessmentItems: any;

    printSub: Subscription;
    patientSub: Subscription;
    imgQueueLoading: number = 0;

    signatureUrl: string = null;
    guardianSignatureUrl: string = null;

    constructor(
        public printSvc: PrintService,
        public filesService: FilesService,
        public sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.printSvc.contentReady = false;
        this.printSvc.hideSignatures.next(true);
        this.patientSub = this.printSvc.activePatient.subscribe(
            (patient: any) => {
                this.patient = patient;
            }
        )
        this.printSub = this.printSvc.toPrintData.subscribe(
            (printData: any) => {
                if (printData && printData.assessment) {
                    this.assessment = printData.assessment;
                    this.assessJSON = JSON.parse(this.assessment.consentJson);
                    this.assessJSONHTML  = this.sanitizeHtml(this.assessJSON.text);
                    let signatureObservable = this.filesService.getFilenameDataUri(this.assessment.signatureUrl).pipe(
                        catchError(error => {
                            return of(null);
                        })
                    );
                    let guardianSignatureObservable = this.filesService.getFilenameDataUri(this.assessment.guardianSignatureUrl).pipe(
                        catchError(error => {
                            return of(null);
                        })
                    );
                    let images: any[] = [];
                    forkJoin({
                        signatureData: signatureObservable,
                        guardianSignatureData: guardianSignatureObservable
                    }).subscribe((results) => {
                        this.signatureUrl = results.signatureData.uri;
                        this.guardianSignatureUrl= results.guardianSignatureData.uri;
                        images.push(this.signatureUrl);
                        if (this.guardianSignatureUrl) {
                            images.push(this.guardianSignatureUrl);
                        }
                        this.loadImages(images);
                    });

                }
            }
        );

    }

    ngOnDestroy() {
        this.printSub.unsubscribe();
        this.patientSub.unsubscribe();
    }

    print() {
        if (this.imgQueueLoading <= 0) {
            this.printSvc.contentReady = true;
        }
    }


    loadImages(images?: any) {
        if (!images || images.length == 0) {
            setTimeout(() => {
                this.print();
            }, 20);
        }
        this.imgQueueLoading = images.length;
        images.forEach((src: string) => {
            var img = new Image();
            img.onload = (e) => {
                this.imgQueueLoading -= 1;
                if (this.imgQueueLoading <= 0) {
                    setTimeout(() => {
                        this.print();
                    }, 100);
                }
            };
            img.onerror = (e) => {
                this.imgQueueLoading -= 1;
                if (this.imgQueueLoading <= 0) {
                    setTimeout(() => {
                        this.print();
                    }, 100);
                }
            };
            img.src = src;
        });
    }
    sanitizeHtml(parseJson: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(parseJson);
    }
}
