<div class="assessment">
    <h3 class="text-center mt-4 mb-1">COVID-19 ESP PBE SELF-ASSESSMENT FORM</h3>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>Assessment</th>
                <th class="text-center">PATIENT</th>
                <th class="text-center">COMPANION</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    Body Temperature (&deg;C)
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.temp > 0"> {{ assessJSON.temp }} </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_temp > 0"> {{ assessJSON.g_temp }} </label>
                </td>
            </tr>

            <tr>
                <td>
                    1. Have you <b>TRAVELED</b> to any country within the last 14 days prior to your scheduled
                    appointment?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_1==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_1==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_1==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_1==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    If YES, please specify country(ies) or city(ies) visited.
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_1_c"> {{ assessJSON.q_1_c }} </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_1_c"> {{ assessJSON.g_q_1_c }} </label>
                </td>
            </tr>
            <tr>
                <td>
                    If YES, when did you arrive in the Philippines?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_1_d"> {{ assessJSON.q_1_d | date: 'mediumDate' }} </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_1_d"> {{ assessJSON.g_q_1_d | date: 'mediumDate' }} </label>
                </td>
            </tr>

            <tr>
                <td>
                    2. Have you been in <b>close contact </b> <u>with someone who arrived from abroad</u> in the last
                    14 days?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_2==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_2==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_2==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_2==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    3. Have you been in a <b>red zone area</b> (cities with high incidence of COVID-19 positive
                    cases) for the past 14 days?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_3==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_3==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_3==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_3==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    4. Have you been in <b>close contact</b> with anyone from a red zone area within the 14 days?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_4==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_4==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_4==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_4==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    5. Have you attended a <b>mass gathering</b>, a <b>reunion of relatives or friends, parties</b>
                    within the last 14 days?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_5==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_5==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_5==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_5==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    6. Have yo been in <b>close contact</b> with a <u>confirmed probable, suspect COVID-19 positive
                        person</u> in the last 14 days?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_6==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_6==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_6==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_6==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    7. Have you been in <b>close contact</b> with a <u>recovered COVID-19 person</u> in the last 14
                    days?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_7==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_7==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_7==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_7==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    8. Have you been <b>diagnosed with COVIDE-19?</b>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_8==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_8==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_8==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_8==='0'"> NO </label>
                </td>
            </tr>
            <tr>
                <td>
                    If YES, when?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_8_d"> {{ assessJSON.q_8_d | date: 'mediumDate' }} </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_8_d"> {{ assessJSON.g_q_8_d | date: 'mediumDate' }} </label>
                </td>
            </tr>

            <tr>
                <td>
                    9. Do you have a <b>flu-like symptoms</b> such as <u>fever</u>, <u>cought</u>, <u>runny
                        nose</u>, <u>sore throat</u>, <u>headache</u>, <u>shortness or breath</u>, <u>chills</u>,
                    <u>general malaise</u>, <u>diarrhea</u>?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_9==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_9==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_9==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_9==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    10. Have you had any of the mentioned symptoms on #9 within the last 14 days?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.q_10==='1'"> YES </label>
                    <label *ngIf="assessJSON.q_10==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_q_10==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_q_10==='0'"> NO </label>
                </td>
            </tr>


        </tbody>
    </table>

    <h4 class="text-uppercase text-center mt-4 mb-1">Oath of Affirmation</h4>
    <p>
        I <b><u> {{ [patient.firstname, patient.middlename, patient.lastname].join(' ') }} </u></b>, do solemnly swear
        under oath states that my declaration in the Patient's Form are True and that I understand that should it be
        discovered to be false later on, I will be subjected to Criminal Prosecution and subject my estate for
        claims against it for damages.
    </p>

    <div class="text-center">
        <div class="signagure"> <img src="{{ signatureUrl }}" /> </div>
        <div> <label class="control-label text-center text-uppercase">Patient Signature</label> </div>
    </div>

    <div class="text-center" *ngIf="assessment.guardianSignatureUrl">
        <div class="signagure">
            <img src="{{ guardianSignatureUrl }}" />
            <h1 class="text-center" *ngIf="assessJSON.guardian_name">
                {{ assessJSON.guardian_name }}
            </h1>
        </div>
        <div> <label class="control-label text-center text-uppercase">Companion Name and Signature</label> </div>
    </div>


    <h4 class="text-center m-4"> <b> RA11332 Law on Reporting of Communicable Diseases </b> </h4>
    <p>
        <b>Section 9.d</b> "Non-cooperation of persons or entities that should report or respond to notifiable
        diseases or health events of public concern"
    </p>
    <p>
        <b>Section 10</b> "fine of 20,000.00 to 50,000.00 or be imprisoned for 1 to 6 months or both"
    </p>

    <div class="notary mt-4">
        SUBSCRIBE AND SWORN to before my Notary Public affiant exhibiting his/her government issued ID No.
        ___________________ issued on ___________________ issued at ____________________________________________. Done
        on the day of __________________, _____ in __________________________.
    </div>
</div>
