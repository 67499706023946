<div class="assessment">
    <h3 class="text-center mt-4 mb-2">DOH COVID-19 Health Questionnaire</h3>

    <table class="table mb-1">
        <tbody>
            <tr>
                <th colspan="2">Travel History</th>
            </tr>
            <tr>
                <td width="65%">
                    Have you ever visited any countries a month prior to your visit to our health care facility?
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.th=='1'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.th=='0'">
                            NO
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    If YES, when did you arrive in the Philippines?
                </td>
                <td>
                    <label *ngIf="assessJSON.th_d">
                        {{ assessJSON.th_d | date:'mediumDate'}}
                    </label>
                </td>
            </tr>

            <tr>
                <th colspan="2">History of 2019 NCoV Exposure</th>
            </tr>

            <tr>
                <td>
                    Have you had close contact with Persons with confirmed case of Covid-19?
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.hne_c=='1'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.hne_c=='0'">
                            NO
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Have you had close contact with Persons under investigation (PUI)?
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.hne_pui=='1'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.hne_pui=='0'">
                            NO
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Have you had close contact with Persons under monitoring (PUM)?
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.hne_pum=='1'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.hne_pum=='0'">
                            NO
                        </label>
                    </div>

                </td>
            </tr>

            <tr>
                <td>
                    If YES, how many times (total)?
                </td>
                <td>
                    <label *ngIf="assessJSON.hne_total">
                        {{ assessJSON.hne_total}}
                    </label>
                </td>
            </tr>
            <tr>
                <td>
                    If YES, how long each time?
                </td>
                <td>
                    <label *ngIf="assessJSON.hne_time">
                        {{ ['< 5 minutes',' 5 - 15 minutes','> 15 minutes','Face to face exposure','Face to face exposure with personal protective equipment (PPE)'][assessJSON.hne_time-1]}}
                    </label>

                    <div *ngIf="assessJSON.hne_ppe">
                        PPE Type:
                        <label>
                            {{ assessJSON.hne_ppe }}
                        </label>
                    </div>

                </td>
            </tr>

            <tr>
                <th colspan="2">SYMPTOMS</th>
            </tr>
            <tr>
                <td>
                    Have you ever experienced any respiratory symptoms ( <b>sore throat, runny nose, cough,
                        shortness of breath</b> ) prior to your visit to our health care facility?
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds=='1'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds=='0'">
                            NO
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Date of onset of symptoms
                </td>
                <td>
                    <label *ngIf="assessJSON.ds_d">
                        {{ assessJSON.ds_d | date:'mediumDate'}}
                    </label>
                </td>
            </tr>
            <tr>
                <td>
                    Fever
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_f=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_f=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_f=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Maximum Temperature (&deg;C)
                </td>
                <td>
                    <label *ngIf="assessJSON.ds_t">
                        {{ assessJSON.ds_t}}
                    </label>
                </td>
            </tr>

            <tr>
                <th colspan="2">Please indicate if you are experiencing the following symptoms:</th>
            </tr>
            <tr>
                <td>
                    Sore throat
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_st=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_st=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_st=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Cough
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_co=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_co=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_co=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Runny nose
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_rn=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_rn=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_rn=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Shortness of breath
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_sb=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_sb=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_sb=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>

            <tr>
                <th colspan="2">Other symptoms:</th>
            </tr>
            <tr>
                <td>
                    Chills
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_ch=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_ch=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_ch=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Nausea
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_na=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_na=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_na=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Diarrhea
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_di=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_di=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_di=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Headaches
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_ha=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_ha=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_ha=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Joint aches
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_ja=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_ja=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_ja=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Muscle aches
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_ma=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_ma=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_ma=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    General malaise
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_gm=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_gm=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_gm=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td>
                    Loss of apetite
                </td>
                <td>

                    <div *ngIf="assessJSON">
                        <label *ngIf="assessJSON.ds_la=='2'">
                            YES
                        </label>
                        <label *ngIf="assessJSON.ds_la=='1'">
                            NO
                        </label>
                        <label *ngIf="assessJSON.ds_la=='0'">
                            Unknown
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <span>Other symptoms: </span>
                    <label *ngIf="assessJSON.others">
                        {{ assessJSON.others}}
                    </label>
                </td>
            </tr>

        </tbody>
    </table>

    <h4 class="text-uppercase text-center mt-4 mb-1">Oath of Affirmation</h4>
    <p>
        I <b><u> {{ [patient.firstname, patient.middlename, patient.lastname].join(' ') }} </u></b>, do solemnly swear
        under
        oath states that my declaration in the Patient's Form are True and that I understand that should it be
        discovered to be false later on, I will be subjected to Criminal Prosecution and subject my estate for
        claims against it for damages.
    </p>

    <div class="text-center">
        <div class="signagure"> <img src="{{ signatureUrl }}" /> </div>
        <div> <label class="control-label text-center text-uppercase">Patient Name and Signature</label> </div>
    </div>

    <h4 class="text-center m-4"> <b> RA11332 Law on Reporting of Communicable Diseases </b> </h4>
    <p>
        <b>Section 9.d</b> "Non-cooperation of persons or entities that should report or respond to notifiable
        diseases or health events of public concern"
    </p>
    <p>
        <b>Section 10</b> "fine of 20,000.00 to 50,000.00 or be imprisoned for 1 to 6 months or both"
    </p>

    <div class="notary mt-4">
        SUBSCRIBE AND SWORN to before my Notary Public affiant exhibiting his/her government issued ID No.
        ___________________ issued on ___________________ issued at ____________________________________________. Done
        on the day of __________________, _____ in __________________________.
    </div>
</div>
