import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

// PIPES
import { AgePipe } from './pipes/age.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { OrderModule } from 'ngx-order-pipe';
import { MyFilterSwPipe } from './pipes/my-filter-sw.pipe';
import { FilterAssocPipe } from './pipes/filter-assoc.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

// IMPORTS
import { FlatpickrModule } from 'angularx-flatpickr';
import { ImageDrawingModule } from 'ngx-image-drawing';
import { QRCodeModule } from 'angularx-qrcode';
import { JoyrideModule } from 'ngx-joyride';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { MaterialModule } from './material.module';

// COMPONENTS
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { ControlMessagesComponent } from './components/control-messages/control-messages.component';
import { CommonFooterComponent } from './components/common-footer/common-footer.component';
import { CommonNavbarComponent } from './components/common-navbar/common-navbar.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { ProfilePopupComponent } from './components/profile-popup/profile-popup.component';
import { ProfilePopupStaffComponent } from './components/profile-popup-staff/profile-popup-staff.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { PatientSearchTypeaheadComponent } from './components/patient-search-typeahead/patient-search-typeahead.component';
import { PatientSearchNewComponent } from './components/patients/patient-search-new/patient-search-new.component';
import { PatientNewFormComponent } from './components/patients/patient-new-form/patient-new-form.component';
import { CommonBillDetailsComponent } from './components/common-bill-details/common-bill-details.component';
import { BillspaymentsBillsComponent } from './components/billspayments-bills/billspayments-bills.component';
import { BillspaymentsFormBillComponent } from './components/billspayments-form-bill/billspayments-form-bill.component';
import { PrintTemplateComponent } from './components/prints/print-template/print-template.component';
import { WaitlistSidebarComponent } from './components/waitlist-sidebar/waitlist-sidebar.component';
import { PrintTemplateLetterComponent } from './components/prints/print-template-letter/print-template-letter.component';
import { PrintRecallComponent } from './components/prints/print-recall/print-recall.component';
import { PrintHalfLetterTemplateComponent } from './components/prints/print-half-letter-template/print-half-letter-template.component';
import { PrintLetterTemplateComponent } from './components/prints/print-letter-template/print-letter-template.component';
import { NoteFormComponent } from './components/patients/note-form/note-form.component';
import { SmsPopupFormComponent } from './components/sms/sms-popup-form/sms-popup-form.component';
import { LoadSmsCreditsFormComponent } from './components/sms/load-sms-credits-form/load-sms-credits-form.component';
import { ImageEditInlineComponent } from './components/patients/image-edit-inline/image-edit-inline.component';
import { ImageEditPopupComponent } from './components/patients/image-edit-popup/image-edit-popup.component';
import { SelectTeethModelsComponent } from './components/select-teeth-models/select-teeth-models.component';
import { ProfileSwitcherComponent } from './components/profile-switcher/profile-switcher.component';
import { SignaturePadPatientComponent } from './components/signature-pad-patient/signature-pad-patient.component';
import { SignaturePadPopupComponent } from './components/signature-pad-popup/signature-pad-popup.component';
import { AdminTourComponent } from './components/virtual-tour/admin-tour/admin-tour.component';
import { PracticeTourComponent } from './components/virtual-tour/practice-tour/practice-tour.component';
import { PrintCovidAssessmentComponent } from './components/prints/print-covid-assessment/print-covid-assessment.component';
import { BuyCreditsComponent } from './components/sms/buy-credits/buy-credits.component';
import { PayFormComponent } from './components/payments/pay-form/pay-form.component';
import { AccountDisabledComponent } from './components/account-disabled/account-disabled.component';
import { TopnavNotificationsComponent } from './components/topnav-notifications/topnav-notifications.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PayGcashBankTransferFormComponent } from './components/payments/pay-gcash-bank-transfer-form/pay-gcash-bank-transfer-form.component';
import { PrintCovidPpdsiAssessmentComponent } from './components/prints/print-covid-ppdsi-assessment/print-covid-ppdsi-assessment.component';
import { PpdsInformedConsentWaiverComponent } from './components/prints/ppds-informed-consent-waiver/ppds-informed-consent-waiver.component';
import { PrintCovidEsppbeAssessmentComponent } from './components/prints/print-covid-esppbe-assessment/print-covid-esppbe-assessment.component';
import { PrintCovidDohAssessmentComponent } from './components/prints/print-covid-doh-assessment/print-covid-doh-assessment.component';
import { PrintInformedConsentComponent } from './components/prints/print-informed-consent/print-informed-consent.component';
import { QrcodeGenPopupComponent } from './components/qrcode-gen-popup/qrcode-gen-popup.component';
import { ConsentCovidFormComponent } from './components/consents/consent-covid-form/consent-covid-form.component';
import { CovidDohFormComponent } from './components/consents/covid-doh-form/covid-doh-form.component';
import { CovidEsppbeFormComponent } from './components/consents/covid-esppbe-form/covid-esppbe-form.component';
import { CovidPpdsiFormComponent } from './components/consents/covid-ppdsi-form/covid-ppdsi-form.component';
import { BulkSmsFormComponent } from './components/sms/bulk-sms-form/bulk-sms-form.component';
import { InformedConsentComponent } from './components/consents/informed-consent/informed-consent.component';
import { ExpenseFormComponent } from './components/expenses/expense-form/expense-form.component';
import { MoveApptFormComponent } from './components/patients/move-appt-form/move-appt-form.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { AngularSignaturePadModule  } from '@almothafar/angular-signature-pad';
import { PrintOptionComponent } from './components/prints/print-option/print-option.component';
import { BuyMoreStorageComponent } from './components/storage/buy-more-storage/buy-more-storage.component';
import { CommonAddPaymentFormComponent } from './components/common-add-payment-form/common-add-payment-form.component';
import { FormatNumberWithCommaComponent } from './components/format-number-with-comma/format-number-with-comma.component';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { PrintGenerationComponent } from './components/prints/print-generation/print-generation.component';
import { CachedImageComponent } from './components/cached-image/cached-image.component';
import { PaymentDatePopupComponent } from './components/payment-date-popup/payment-date-popup.component';
import { CommonBulkPaymentFormComponent } from './components/common-bulk-payment-form/common-bulk-payment-form.component';
import { InputMatChipsComponent } from './components/input-mat-chips/input-mat-chips.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { CustomInputServiceComponent } from './components/custom-input-service/custom-input-service.component';

@NgModule({
  exports: [
    CommonModule,
    ToggleFullscreenDirective,
    NgbModule,
    ControlMessagesComponent,
    CommonFooterComponent,
    CommonNavbarComponent,
    ProfileMenuComponent,
    PatientSearchTypeaheadComponent,
    PatientSearchNewComponent,
    PatientNewFormComponent,
    CommonBillDetailsComponent,
    PrintTemplateComponent,
    PrintTemplateLetterComponent,
    PrintRecallComponent,
    WaitlistSidebarComponent,
    AgePipe,
    SafeUrlPipe,
    MyFilterSwPipe,
    FilterAssocPipe,
    NoteFormComponent,
    SmsPopupFormComponent,
    ImageEditPopupComponent,
    ImageEditInlineComponent,
    ProfileSwitcherComponent,
    SignaturePadPatientComponent,
    AdminTourComponent,
    PracticeTourComponent,
    BuyCreditsComponent,
    PayFormComponent,
    PayGcashBankTransferFormComponent,
    AccountDisabledComponent,
    TopnavNotificationsComponent,
    ExpenseFormComponent,
    PrintLetterTemplateComponent,
    PrintHalfLetterTemplateComponent,
    MoveApptFormComponent,
    ChangeEmailComponent,
    BuyMoreStorageComponent,
    CommonAddPaymentFormComponent,
    NumberFormatPipe,
    FormatNumberWithCommaComponent,
    CachedImageComponent,
    PaymentDatePopupComponent,
    CommonBulkPaymentFormComponent,
    InputMatChipsComponent,
    CustomInputServiceComponent
  ],
  imports: [
    MaterialModule,
    MatChipsModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatListModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    OrderModule,
    FlatpickrModule,
    NgxPaginationModule,
    FilterPipeModule,
    ImageDrawingModule,
    CreditCardDirectivesModule,
    AngularSignaturePadModule ,
    JoyrideModule,
    QRCodeModule,
    QuillModule,
    MaterialModule,
    ReactiveFormsModule,
    NgbNavModule
  ],
  declarations: [
    ToggleFullscreenDirective,
    ControlMessagesComponent,
    CommonFooterComponent,
    CommonNavbarComponent,
    AgePipe,
    ProfileMenuComponent,
    ProfilePopupComponent,
    ProfilePopupStaffComponent,
    PasswordChangeComponent,
    PatientSearchTypeaheadComponent,
    PatientSearchNewComponent,
    PatientNewFormComponent,
    CommonBillDetailsComponent,
    BillspaymentsBillsComponent,
    BillspaymentsFormBillComponent,
    PrintTemplateComponent,
    WaitlistSidebarComponent,
    PrintTemplateLetterComponent,
    PrintRecallComponent,
    PrintHalfLetterTemplateComponent,
    PrintLetterTemplateComponent,
    MyFilterSwPipe,
    NoteFormComponent,
    FilterAssocPipe,
    SmsPopupFormComponent,
    LoadSmsCreditsFormComponent,
    ImageEditInlineComponent,
    ImageEditPopupComponent,
    SelectTeethModelsComponent,
    ProfileSwitcherComponent,
    SignaturePadPatientComponent,
    SignaturePadPopupComponent,
    AdminTourComponent,
    PracticeTourComponent,
    PrintCovidAssessmentComponent,
    BuyCreditsComponent,
    SafeUrlPipe,
    PayFormComponent,
    AccountDisabledComponent,
    TopnavNotificationsComponent,
    NotificationsComponent,
    PayGcashBankTransferFormComponent,
    PrintCovidPpdsiAssessmentComponent,
    PpdsInformedConsentWaiverComponent,
    PrintCovidEsppbeAssessmentComponent,
    PrintCovidDohAssessmentComponent,
    PrintInformedConsentComponent,
    QrcodeGenPopupComponent,
    ConsentCovidFormComponent,
    CovidDohFormComponent,
    CovidEsppbeFormComponent,
    CovidPpdsiFormComponent,
    BulkSmsFormComponent,
    InformedConsentComponent,
    ExpenseFormComponent,
    MoveApptFormComponent,
    ChangeEmailComponent,
    PrintOptionComponent,
    BuyMoreStorageComponent,
    CommonAddPaymentFormComponent,
    FormatNumberWithCommaComponent,
    CachedImageComponent,
    NumberFormatPipe,
    PrintGenerationComponent,
    PaymentDatePopupComponent,
    CommonBulkPaymentFormComponent,
    InputMatChipsComponent,
    CustomInputServiceComponent
  ],
  providers: [AgePipe, NumberFormatPipe]
})
export class SharedModule {}
