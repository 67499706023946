import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';

import { tap, map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { PatientFile, CrudListResponses } from '@app/shared/models';
import { NgxSpinnerService } from 'ngx-spinner';

import swal from 'sweetalert2';
import { AccountService } from '@app/core/account/services/account.service';
import { EXPIRATION_FOR_DIGITAL_OCEAN } from '@app/shared/constants/Expiration.constants';
import { GetAPIResponse } from '@app/shared/models/crud-responses';
import { PAGINATED } from '@app/shared/constants/RecordTypeConstant';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  url = environment.prctUrl + '/patient_files';
  files: Observable<PatientFile[]>;
  private _files: PatientFile[] = [];
  file: PatientFile;
  public gettingRecords: boolean = false;
  params: any = {
		per_page: 100,
		limit: 100,
		page: 1,
		total: 0,
		sortField: 'added_dt',
		sortDirection: 'desc',
		recordType: PAGINATED
	};
  records: Observable<[]>;
	private readonly _record = new BehaviorSubject<GetAPIResponse>({});
	public record$ = this._record.asObservable();
	private _records;
  constructor(private http: HttpClient, private spinner: NgxSpinnerService, private accountSvc: AccountService) {
    // this.getInitRecords().subscribe();
  }

  getPresignedFilename(filename: string, base64?: any): Observable<any> {
    let params: any = {
      filename: filename
    };
    if (base64) {
      params.base64 = 1;
    }
    const cachedFile: any = localStorage.getItem(filename);
    if (cachedFile) {
      return of({ filename: cachedFile });
    }
    return this.http.get(`${environment.prctUrl}/presigned`, { params: params }).pipe(
      tap((resp: any) => {
        if (resp.filename) {
          localStorage.setItem(filename, resp.filename);
        }
      })
    );
  }

  getFilename(id: number): Observable<any> {
    let params = new HttpParams();
    params.append('linkonly', '1');
    return this.http.get(`${this.url}/${id}/filename`).pipe(tap((resp: any) => {}));
  }

  getFiles(options?: any) {
    this.gettingRecords = true;
    let params = new HttpParams();
    Object.keys(options).forEach(function (key) {
      params = params.append(key.toString(), options[key]);
    });
    return this.http.get(`${this.url}`, { params }).pipe(
      tap((data: CrudListResponses) => {
        this.gettingRecords = false;
        this.files = of(<PatientFile[]>data.rows);
        this._files = <PatientFile[]>data.rows;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getPatientFiles(options?: any) {
    this.gettingRecords = true;
    let params = new HttpParams();
    Object.keys(options).forEach(function (key) {
      params = params.append(key.toString(), options[key]);
    });
    return this.http.get(`${environment.apiUrlV2}/file/patient-files`, { params }).pipe(
      tap((data: GetAPIResponse) => {
        this.gettingRecords = false;
        this.files = of(<PatientFile[]>data.data);
        this._files = <PatientFile[]>data.data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getProgressNotesFiles(id: number) {
    return this.http.get(`${this.url}/${id}/list`, {}).pipe(
      tap((resp: any) => {
        // console.log(resp);
      })
    );
  }

  getLabFiles(options?: any) {
    this.gettingRecords = true;
    let params = new HttpParams();
    let url = environment.prctUrl + '/laboratory_files';
    Object.keys(options).forEach(function (key) {
      params = params.append(key.toString(), options[key]);
    });
    return this.http.get(`${url}`, { params }).pipe(
      tap((data:CrudListResponses) => {
        this.gettingRecords = false;
        this.files = of(<PatientFile[]>data.rows);
        this._files = <PatientFile[]>data.rows;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  get(id: number): Observable<PatientFile> {
    if (this.file && this.file.id == id) {
      return of(this.file);
    }
    if (this._files && this._files.length > 0) {
      this.file = this._files.find((row) => row.id == id);
      return of(this.file);
    } else {
      return this.http.get(`${this.url}/${id}`).pipe(
        tap(
          (data: PatientFile) => {
            this.file = data;
            return of(data);
          },
          catchError((error) => {
            return throwError(error);
          })
        )
      );
    }
  }
  // deprecated method
  create(data: any): Observable<PatientFile> {
    console.log('create file', data);
    if (!this.accountSvc.canUploadFiles) {
      swal({
        title: 'Upload error!',
        text: 'You have already used up your free 10GB storage. Please subscribe for additional storage to continue uploading files.',
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6'
      });
      return of(null);
    }

    this.spinner.show();
    return this.http.post(`${this.url}`, data).pipe(
      tap(
        (res: any) => {
          this.spinner.hide();
          data.id = res.id;
          if (res.account_id) {
            data.account_id = res.account_id;
          }
          if (res.status) {
            data.status = res.status;
          }
          if (res.filenames) {
            data.filenames = res.filenames;
          }
          data.remarks = data.get('remarks');
          data.added_dt = new Date();
          this._files.splice(0, 0, <PatientFile>data);
          this.file = <PatientFile>data;
          return of(<PatientFile>data);
        },
        (error) => {
          this.spinner.hide();
          swal({
            title: 'Save error!',
            text: error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }
  // end of deprecated method create
  update(id: number, data: any): Observable<PatientFile> {
    this.spinner.show();
    return this.http.post(`${this.url}/${id}`, data).pipe(
      tap(
        (res: any) => {
          this.spinner.hide();
          let files = this._files.find((row) => row.id == id);
          if (files) {
            files = <PatientFile>Object.assign(files, data);
          }
          this.file = <PatientFile>data;
        },
        (error) => {
          swal({
            title: 'Update error!',
            text: error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete(`${this.url}/${id}`).pipe(
      tap((data) => {
        let file = this._files.find((row) => row.id == id);
        this._files.splice(this._files.indexOf(file), 1);
      }),
      map(() => true),
      catchError((error) => {
        swal({
          title: 'Delete error!',
          text: error.error.message,
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6'
        });
        return throwError(error);
      })
    );
  }
  getImgToDataUrl(url) {
    return fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  }

  getPresignedFilenameDataUri(filename: string): Observable<any> {
    let params: any = {
      filename: filename,
      base64: 1
    };
    return this.http.get(`${environment.prctUrl}/presigned`, { params: params }).pipe(tap((resp: any) => {}));
  }

  // new endpoint for digital ocean 
  getFilenameDataUri(filename: string): Observable<any> {
    let params: any = {
      filename: filename,
      expiration_time: EXPIRATION_FOR_DIGITAL_OCEAN,
    };
    return this.http.get(`${environment.apiUrlV2}/file/retrieve-file`, { params: params }).pipe(tap((resp: any) => {}));
  }

  getPresignedFilenameDataUriV2(filename: string): Observable<any> {
    let params: any = {
      filename: filename,
      expiration_time: EXPIRATION_FOR_DIGITAL_OCEAN,
    };
      const cachedFile: any = localStorage.getItem(filename);
    if (cachedFile) {
      return of({ filename: cachedFile });
    }
      return this.http.get(`${environment.apiUrlV2}/file/patient-files/presigned`, { params: params }).pipe(
      tap((resp: any) => {
        if (resp.filename) {
          try {
            // Try to cache the file in localStorage
            localStorage.setItem(filename, resp.filename);
          } catch (e) {
            // Handle LocalStorage quota exceeded error
            if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
              console.warn('LocalStorage quota exceeded. Skipping file caching.');
            } else {
              swal({
                title: 'Error!',
                text: 'An unexpected error occurred while saving the file. Please try again later.',
                type: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            });
            }
          }
        }
      }),
      catchError(error => {
        swal({
          title: 'HTTP Error!',
          text: 'An error occurred while retrieving the presigned URL. Please try again later.',
          type: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
      });
        console.error('HTTP Error:', error);
        return of({ filename: null });
      })
    );
  }  

  createRecord(data: any): Observable<PatientFile> {
    if (!this.accountSvc.canUploadFiles) {
      swal({
        title: 'Upload error!',
        text: 'You have already used up your free 10GB storage. Please subscribe for additional storage to continue uploading files.',
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6'
      });
      return of(null);
    }

    this.spinner.show();
    return this.http.post(`${environment.apiUrlV2}/file/patient-files`, data).pipe(
      tap(
        (res: any) => {
          this.spinner.hide();
          data.id = res.id;
          if (res.account_id) {
            data.account_id = res.account_id;
          }
          if (res.status) {
            data.status = res.status;
          }
          if (res.filenames) {
            data.filenames = res.filenames;
          }
          data.remarks = data.get('remarks');
          data.added_dt = new Date();
          this._files.splice(0, 0, <PatientFile>data);
          this.file = <PatientFile>data;
          return of(<PatientFile>data);
        },
        (error) => {
          this.spinner.hide();
          swal({
            title: 'Save error!',
            text: error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }
  deleteRecord(id: number): Observable<boolean> {
    const url = `${environment.apiUrlV2}/file/patient-files/${id}`;
    return this.http.delete(url).pipe(
      tap(() => {
        let file = this._files.find((row) => row.id === id);
        if (file) {
          this._files.splice(this._files.indexOf(file), 1);
        }
      }),
      map(() => true),
      catchError((error) => {
        swal({
          title: 'Delete error!',
          text: error.error.message,
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6'
        });
        return throwError(error);
      })
    );
  }

  updateRecord(id: number, data: any): Observable<PatientFile> {
    this.spinner.show();
    console.log(data);
      return this.http.post(`${environment.apiUrlV2}/file/patient-files/${id}`, data).pipe(
      tap(
        (res: any) => {
          this.spinner.hide();
          let files = this._files.find((row) => row.id == id);
          if (files) {
            files = <PatientFile>Object.assign(files, data);
          }
          this.file = <PatientFile>data;
        },
        (error) => {
          swal({
            title: 'Update error!',
            text: error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }

  setParamsForLaboratoryFiles(params: any) {
    this.params = params;
  }
  refreshListForLaboratoryFiles() {
      this._record.next(null);
      this.getInitialRecordsForLaboratoryFiles(this.params).subscribe();
  }
  getInitialRecordsForLaboratoryFiles(params?: any) {
      this.gettingRecords = true;
      let url = environment.apiUrlV2 + '/v2/patients/laboratory-files';

      return this.http.get(`${url}`, {params: params}).pipe(
          tap(
              (data: GetAPIResponse) => {
                  this.gettingRecords = false;
                  this._record.next(data);
                  this.files = of(<PatientFile[]>data.data);
                  this._files = <PatientFile[]>data.data;
              }
          ),
          catchError(error => {
              return throwError(error);
          })
      );
  }
}
