<div class="print-container ql-snow">
  <div class="header ql-editor">
    <div *ngIf="headerHtml">
      <div class="head-details" id="header-html" [innerHtml]="headerHtml"></div>
      <div class="spacer">&nbsp;</div>
      <div class="patient-details font14">
        <div class="flex-left">
          <div><b>Patient: </b> {{ patient.firstname + ' ' + patient.lastname + ' ' + patient.extname }}</div>
          <div><b>Address: </b> {{ patient.address }}</div>
        </div>
        <div class="flex-right">
          <div><b>Age: </b> {{ patient.birthday | age }}</div>
          <div><b>Date: </b> {{ today | date }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="spacer">&nbsp;</div>

    <img class="rximage" src="/assets/img/rx.jpg" alt="-" *ngIf="printService.showRx.value" />
    <div class="body-details" [innerHtml]="htmToDisplay"></div>
    <div class="spacer">&nbsp;</div>
  </div>

  <div class="footer">
    <div class="flex-left">
      <div class="font14" [innerHtml]="bottomLeftHtml">-</div>
    </div>
    <div class="flex-right">
      <div class="font14">
        <div class="signature" *ngIf="signature">
          <img [src]="signature" alt="" />
        </div>
        <div [innerHtml]="bottomRightHtml"></div>
      </div>
    </div>
  </div>
</div>
