import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { GetAPIResponse } from "@app/shared/models/crud-responses";
import { PAGINATED } from "@app/shared/constants/RecordTypeConstant";
import { Consent } from "@app/shared/models/patients";

@Injectable({
    providedIn: 'root'
})
export class ConsentService {

    apiUrl: string = environment.apiUrl;
    apiUrlV2 = environment.apiUrlV2+ '/v2/patients/consents';

    records: Observable<[]>;
	private readonly _record = new BehaviorSubject<GetAPIResponse>({});
	public record$ = this._record.asObservable();
	public gettingRecords: boolean = false;
	private _records;
	params: any = {
		per_page: 100,
		limit: 100,
		page: 1,
		total: 0,
		sortField: 'created_at',
		sortDirection: 'desc',
		recordType: PAGINATED
	};


    constructor(private http: HttpClient) { }

    setParams(params: any) {
        this.params = params;
    }
    refreshList() {
        this._record.next(null);
        this.getInitialRecords(this.params).subscribe();
    }
    getInitialRecords(params?: any) {
        this.gettingRecords = true;
        return this.http.get(`${this.apiUrlV2}`, {params: params}).pipe(
            tap(
                (data: GetAPIResponse) => {
                    this.gettingRecords = false;
                    this._record.next(data);
                }
            ),
            catchError(error => {
                return throwError(error);
            })
        );
    }

    get(id: number): Observable<Consent> {
        if (this._records && this._records.length > 0) {
			return of(this._records.find(row => row.id == id));
		} else {
			this.http.get(`${this.apiUrlV2}/${id}`).pipe(
				tap(
					(data) => {
						return of(data);
					},
					catchError(error => {
						return throwError(error);
					}))
			)
		}
    }

    updateRecord(newRecord: any, id: number): Observable<Consent> {
        return this.http.post(`${this.apiUrlV2}/${id}`, newRecord).pipe(
            tap((resp: any) => {
                if (resp) {
                    let record: any = this._record.getValue();
                    const idx = record.data.findIndex((row) => {
                        return row.id == id;
                    })
                    record.data.splice(idx, 1, <any>resp);
                    this._record.next(record);
                }
            })
        )
    }

    createRecord(data: any): Observable<Consent> {
        return this.http.post(`${this.apiUrlV2}`, data).pipe(
            tap((resp: any) => {
                if (resp) {
                    let record: any = this._record.getValue();
                    record.data = [resp, ...record.data];
                    record.total += 1;
                    this._record.next(record);
                }
            })
        )
    }

    deleteRecord(id: number): Observable<any> {
        return this.http.delete(`${this.apiUrlV2}/${id}`).pipe(
            tap((data: any) => {
                let record: any = this._record.getValue();
                const idx = record.data.findIndex((row) => {
                    return row.id == id;
                })
                record.data.splice(idx, 1);
                record.total -= 1;
                this._record.next(record);
            })
        )
    }

    getCovidAssessmentItems() {
        const items: any[] = [
            { name: "cv_f", text: "FEVER > 38 Degree Celsius" },
            { name: "cv_ri", text: "Respiratory Infection (cough or colds)" },
            { name: "cv_th", text: "Travel History for the past 14 days" },
            { name: "cv_exp", text: "History of Exposure within 14 days" },
            { name: "cv_pdccv", text: "Providing Direct Care for COVID-19 patient" },
            { name: "cv_wtcv", text: "Working Together or staying the same close environment" },
            { name: "cv_ttcv", text: "Traveling together with COVID-19 patient" },
            { name: "cv_ltcv", text: "Living the same household as COVID-19 patient" },
            { name: "cv_d", text: "Diarrhea" },
            { name: "cv_v", text: "Vaccinated against covid-19" },
        ]
        return items;
    }

    sendFormToPatient(payload): Observable<any> {
        return this.http.post(`${this.apiUrl}/patient_consents/public_link`, payload).pipe(
            tap((resp: any) => {
                if (resp && resp.consent) {
                    const consent = resp.consent;
                    consent.public_link = resp.link;
                    let record: any = this._record.getValue();
                    record.data = [consent, ...record.data];
                    record.total += 1;
                    this._record.next(record);
                }
            })
        );
    }

}
