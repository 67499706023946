<div class="assessment">
    <h3 class="text-center mt-4 mb-1">PPDSI INFORMED CONSENT AND WAIVER</h3>

    <div class="form-inline mt-3">

        <p>
            I <b class="text-capitalize p-1"><u> {{ assessJSON.guardian_name }}</u></b> , parent/guardian of <b
                class="text-capitalize"><u>
                    {{ [patient.firstname, patient.middlename, patient.lastname].join(' ') }}</u></b> hereby
            authorize <b class="text-capitalize p-1"><u> {{ assessJSON.dentist_name }} </u></b> to perform the
            appropriate dental treatment on my child.
            The nature and purpose of the treatment as well as the alternative interventions were thouroughly
            explained to me by <b class="text-capitalize p-1"><u> {{ assessJSON.staff_name }}</u></b>.
        </p>
        <p>
            I understand that I submitted to my child's treatment during the novel Covid Pandemic when the behavior
            of the SARS-Cov-2 is still scientifically uncertain in terms of the exact mode of transmission,
            diagnosis, treatment, infection and immunity.
        </p>
        <p>
            Financial counseling was clearly explained and I recognize the need to include cost of infection control
            gears and equipment in the incurred cost of treatment.
        </p>
        <p>
            I hereby agree not to hold liable <b class="text-capitalize p-1"><u>
                    {{ assessJSON.dentist_name }}</u></b>
            nor any of her/his clinic staff of any legal responsibility for any medical outcome that my child of any
            of our family member may be faced with as a consequence of the dental intervention rendered.
        </p>
        <p>
            In the unfortunate event that one of our family members test Covid19 positive, I agree to voluntarily
            disclose this information to this dental clinic to ensure the safety of the dental staff and the other
            patients.
        </p>


    </div>


    <div class="form-group guardian-sig">
        <div class="text-center">
            <div class="guardian_signature">
                <img src="{{base_url + '/files/' + assessment.guardian_sig_url}}" />
                <h1 class="namesig">{{ assessJSON.guardian_name}}</h1>
                <div> <label class="control-label text-center text-uppercase">Parent/Guardian Signature</label>
                </div>
            </div>
        </div>

    </div>

    <div class="form-group patient-sig" *ngIf="assessJSON.staff_name && assessment.signature_url">
        <div class="text-center">
            <div class="signature"> <img src="{{base_url + '/files/' + assessment.signature_url}}" /> </div>
            <h1 class="namesig">{{assessJSON.staff_name}}</h1>
            <div> <label class="control-label text-center text-uppercase">Dental Staff Signature</label> </div>
        </div>

    </div>

</div>