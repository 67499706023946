import { Component, OnInit, ViewChild } from '@angular/core';

import { AuthService } from '@app/shared/auth/auth.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
	selector: 'app-forgotpasswd',
	templateUrl: './forgotpasswd.component.html',
	styleUrls: ['./forgotpasswd.component.scss']
})
export class ForgotpasswdComponent implements OnInit {

	// @ViewChild('f') loginForm: NgForm;
	model: any = {};

	constructor(
		private auth: AuthService,
		private router: Router
	) { }

	ngOnInit() {
	}

	onSubmit() {
        this.auth.forgotPassword(this.model.email).subscribe(
            (data: any) => {
				swal(data,'','success');
				this.router.navigate(['/auth/login']);
            }, error => {
                swal(error.error.error,'','error');
            })
    }

}
