import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'age'
})
export class AgePipe implements PipeTransform {

    transform(value: Date): string {
        if (!value || value.toString() == '0000-00-00') {
            return '';
        }
        let today = moment();
        let birthdate = moment(value);
        let years = today.diff(birthdate, 'years');
        if (isNaN(years)) {
            return '';
        } else {
            let html: string = years + " years old ";
            // html += today.subtract(years, 'years').diff(birthdate, 'months') + " mo";
            return html;
        }

    }

}