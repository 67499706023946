import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  constructor() {}

  transform(number: any): string {
    let numberValue = 0;
    if (typeof number === 'string') {
      numberValue = parseFloat(number.replace(/,/g, '')) || 0;
    }else{
      numberValue = number;
    }
    const formattedValue  = numberValue.toLocaleString()
    return formattedValue;
  }
}
