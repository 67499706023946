import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PrintService } from '@app/shared/services/helpers/print.service';
import { SettingsService } from '@app/shared/services/api/settings.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { Associate, Patient } from '@app/shared/models';
import { AssociatesService } from '@app/shared/services/api/associates.service';
import { environment } from '@env/environment';
import { CacheService } from '@app/shared/services/helpers/cache.service';

import { HttpClient } from '@angular/common/http';
import {
  ALLOW_STAFF_PRINT,
  PRINT_TYPE_BILL,
  PRINT_TYPE_CERTIFICATE,
  PRINT_TYPE_CHART,
  PRINT_TYPE_PRESCRIPTION,
  PRINT_TYPE_TREATMENT_PLAN
} from '@app/shared/constants/settings.constants';
import { catchError, switchMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { FilesService } from '@app/shared/services/api/files.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-print-letter-template',
  templateUrl: './print-letter-template.component.html',
  styleUrls: ['./print-letter-template.component.scss']
})
export class PrintLetterTemplateComponent implements OnInit {
  url: string = environment.apiUrlV2;

  htmToDisplay: SafeHtml = '';
  headerHtml: string = '';
  bottomRightHtml: string = '';
  bottomLeftHtml: string = '-';
  patient: Patient;
  profile: any = {};
  today: Date;
  showSignatures: boolean = false;

  signature: any;
  clinicHeaderPrint: any;
  associatePrint: any = {};
  allowStaffPrintBill: boolean = false;
  allowStaffPrintPrescription: boolean = false;
  allowStaffPrintTreatmentPlan: boolean = false;
  allowStaffPrintCertificate: boolean = false;
  allowStaffPrintCharts: boolean = false;

  constructor(
    public printService: PrintService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private cacheService: CacheService,
    private filesService: FilesService,
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getSettingsHeaderAndPrivilege()
      .pipe(switchMap(() => this.authService.authenticationState))
      .subscribe((value) => {
        if (value) {
          this.authService.loginProfile$.subscribe((data) => {
            this.profile = data;

            if (this.authService.user.data.login_type == 16) {
              this.doPrint();
            } else {
              this.doPrint();
            }
          });
        }
      });
  }

  loadImages(images?: any) {
    if (!images || images.length == 0) {
      setTimeout(() => {
        this.printService.onDataReady();
      }, 10);
    }
    let totalImages: number = images.length;

    images.forEach((src: string) => {
      var img = new Image();
      img.onload = (e) => {
        totalImages -= 1;
        if (totalImages <= 0) {
          setTimeout(() => {
            this.printService.onDataReady();
          }, 100);
        }
      };
      img.onerror = (e) => {
        totalImages -= 1;
        if (totalImages <= 0) {
          setTimeout(() => {
            this.printService.onDataReady();
          }, 100);
        }
      };
      img.src = src;
    });
  }

  doPrint() {
    this.associatePrint = null; // Or however you wish to reset it
    let typePrint;
    this.printService.activePatient.subscribe((patient) => {
      this.patient = patient;
    });

    this.printService.activeAssociate.subscribe((associate) => {
      this.associatePrint = associate;
    });
    this.printService.activePrint.subscribe((data) => {
      // this.htmToDisplay = data;
      typePrint = data;
    });
    let useProfile = this.getDataToUse(this.profile, this.associatePrint, typePrint);

    this.printService.htmlToPrint.subscribe((data) => {
      if (data?.trim()) {
        let decodedHtml = new DOMParser().parseFromString(data, "text/html").body.innerHTML;
        this.htmToDisplay = this.sanitizer.bypassSecurityTrustHtml(decodedHtml);
      }
      this.bottomRightHtml = useProfile;
    });
    this.printService.recordDate.subscribe((data) => {
      if (data && (typeof data === 'string' || typeof data === 'number' || data instanceof Date)) {
        this.today = new Date(data);
      } else {
        this.today = new Date();
      }
    });
  }

  getDataToUse(profileData: any, associateData: any, typePrint: any) {
    let allowToPrint;
    // Helper function to check if any print permissions are true

    const hasAssociatePrintData = associateData && Object.keys(associateData).length > 0;
    let permissionFound = false; // Track if any permission is true

    if (this.authService.isStaff()) {
      if (typePrint === PRINT_TYPE_BILL && this.allowStaffPrintBill && hasAssociatePrintData) {
        allowToPrint = this.createDoctorRxBottomHtml(associateData);
      } else if (typePrint === PRINT_TYPE_PRESCRIPTION && this.allowStaffPrintPrescription && hasAssociatePrintData) {
        allowToPrint = this.createDoctorRxBottomHtml(associateData);
      } else if (
        typePrint === PRINT_TYPE_TREATMENT_PLAN &&
        this.allowStaffPrintTreatmentPlan &&
        hasAssociatePrintData
      ) {
        allowToPrint = this.createDoctorRxBottomHtml(associateData);
      } else if (typePrint === PRINT_TYPE_CERTIFICATE && this.allowStaffPrintCertificate && hasAssociatePrintData) {
        allowToPrint = this.createDoctorRxBottomHtml(associateData);
      } else if (typePrint === PRINT_TYPE_CHART && this.allowStaffPrintCharts && hasAssociatePrintData) {
        allowToPrint = this.createDoctorRxBottomHtml(associateData);
      } else {
        allowToPrint = this.createStaffBottomHtml(profileData);
      }
    } else {
      allowToPrint = this.createDoctorRxBottomHtml(profileData);
    }

    // Fallback to profile data if none of the above conditions are met
    return allowToPrint;
  }

  getProfileProperty(propertyName: string, useProfile: any) {
    // Convert the property name to camelCase
    const camelCaseName = propertyName.replace(/_\w/g, (m) => m[1].toUpperCase());

    const snakeCaseName = camelCaseName.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

    const profileSource = useProfile;

    const value = profileSource ? profileSource[camelCaseName] || profileSource[snakeCaseName] : undefined;

    return value;
  }

  createStaffBottomHtml(useProfile) {
    let firstName =
      this.getProfileProperty('firstname', useProfile) || this.getProfileProperty('firstName', useProfile);
    let lastName = this.getProfileProperty('lastname', useProfile) || this.getProfileProperty('lastName', useProfile);

    let html: string = '<div class="doctor-details">';
    html += '<div class="doctor">Staff: ' + (firstName || '') + ' ' + (lastName || '') + '</div>';
    html += '</div>';
    return html;
  }

  createDoctorRxBottomHtml(useProfile) {
    let prc = this.getProfileProperty('license_no', useProfile) || this.getProfileProperty('licenseNo', useProfile);
    let s2 = this.getProfileProperty('s2_no', useProfile) || this.getProfileProperty('s2No', useProfile) || '';
    let ptr = this.getProfileProperty('ptr_no', useProfile) || this.getProfileProperty('ptrNo', useProfile);
    let firstName =
      this.getProfileProperty('firstname', useProfile) || this.getProfileProperty('firstName', useProfile);
    let lastName = this.getProfileProperty('lastname', useProfile) || this.getProfileProperty('lastName', useProfile);
    let extName = this.getProfileProperty('extname', useProfile) || this.getProfileProperty('extName', useProfile);
    let doctor = 'Dr. ' + (firstName || '') + ' ' + (lastName || '');

    if (extName) {
      doctor = doctor + '-' + extName;
    }

    let html: string = '<div class="doctor-details">';
    html += '<div class="doctor">' + doctor + '</div>';
    html += '<div class="prc">PRC lic no. ' + (prc || '') + '<div>';
    html += '<div class="prc">S2 lic no. ' + s2 + '<div>';
    html += '<div class="prc">PTR no. ' + (ptr || '') + '<div>';
    html += '</div>';
    return html;
  }

  getSettingsHeaderAndPrivilege(): Observable<void> {
    return new Observable((observer) => {
      this.settingsService.getSettings().subscribe((data) => {
        let images: string[] = [];
        const cliniId: string = this.authService.getUserData('clinic_id');
        const signature: string = this.settingsService.get('digital_signature');
        const useSignature: number = parseInt(this.settingsService.get('use_digital_signature'));
        const useDigitalClinidPrintHeader:string = this.settingsService.get('use_digital_clinic_print_header_' + cliniId);
        const printHeaderPerClinicLogo: string = this.settingsService.get('print_header_per_clinic_' + cliniId);

        let signatureObservable: Observable<any | null> = of(null);
        if (useSignature && useSignature == 1 && signature && !this.authService.isStaff()) {
          const cacheSignature = this.cacheService.getCachedSignature();
          signatureObservable = cacheSignature 
          ? of(cacheSignature) 
          : this.filesService.getFilenameDataUri(signature).pipe(catchError(() => of(null)));
        }

        let clinicHeaderObservable: Observable<any | null> = of(null);
        if (parseInt(useDigitalClinidPrintHeader) && printHeaderPerClinicLogo) {
          const cacheUrl: { value: string } = this.cacheService.getCachedPrintLogoPerClinic();
          if (cacheUrl && cacheUrl.value) {
            clinicHeaderObservable = this.filesService
              .getFilenameDataUri(cacheUrl.value)
              .pipe(catchError(() => of(null)));
          } else {
            clinicHeaderObservable = this.filesService
              .getFilenameDataUri(printHeaderPerClinicLogo)
              .pipe(catchError(() => of(null)));
          }
        }else{
          const defaultHeaderUrl: string = `${this.url}/file?f=${this.settingsService.get('print_header_files')}`;
          clinicHeaderObservable = of(defaultHeaderUrl);

        }
     
        forkJoin({
          signatureData: signatureObservable,
          clinicHeaderData: clinicHeaderObservable
        }).subscribe((results) => {
          let headerHtml:string = this.settingsService.get('print_header');
          headerHtml = headerHtml.replace(/<p[^>]*>.*?<\/p>/g, '');
          if (results.signatureData) {
            this.signature = results.signatureData.uri ? results.signatureData.uri : results.signatureData.filename;
            const signatureImgPush = results.signatureData.uri ? results.signatureData.uri : results.signatureData.filename;
            images.push(signatureImgPush);
          }
          if (results.clinicHeaderData) {
            if (parseInt(useDigitalClinidPrintHeader)) {
              const headerHtmlString: SafeHtml = `<center><img src="${results.clinicHeaderData.uri}" width="300" height="150" alt="Image"></center>`;
              images.push(results.clinicHeaderData.uri);
              this.changeDetectorRef.detectChanges();
              this.headerHtml = headerHtmlString.toString();
            }else{
              const mainLogo: { filename?: string } | null = this.cacheService.getCachedPrintLogo() as { filename?: string } | null;
              const clinicHeaderData: { uri?: string } | string | null = results.clinicHeaderData;
              
              // Determine the logo source
              let logoSource: string = '';
              if (mainLogo?.filename) {
                logoSource = mainLogo.filename;
              } else if (typeof clinicHeaderData === 'string') {
                logoSource = clinicHeaderData;
              } else if (clinicHeaderData?.uri) {
                logoSource = clinicHeaderData.uri;
              }
              
              // Build the header HTML string
              const headerHtmlString: SafeHtml = `<center><img src="${logoSource}" width="300" height="150" alt="Image"></center>`;
              images.push(logoSource);
              this.changeDetectorRef.detectChanges();
              this.headerHtml = headerHtmlString.toString();
            }
          } 

          if (this.settingsService.get('allow_staff_treatment_plan_dentist_details_print') == ALLOW_STAFF_PRINT) {
            this.allowStaffPrintTreatmentPlan = true;
          }
          if (this.settingsService.get('allow_staff_prescription_dentist_details_print') == ALLOW_STAFF_PRINT) {
            this.allowStaffPrintPrescription = true;
          }
          if (this.settingsService.get('allow_staff_bills_payment_dentist_details_print') == ALLOW_STAFF_PRINT) {
            this.allowStaffPrintBill = true;
          }
          if (this.settingsService.get('allow_staff_certificate_dentist_details_print') == ALLOW_STAFF_PRINT) {
            this.allowStaffPrintCertificate = true;
          }
          if (this.settingsService.get('allow_staff_charts_dentist_details_print') == ALLOW_STAFF_PRINT) {
            this.allowStaffPrintCharts = true;
          }
          this.loadImages(images);

          observer.next();
          observer.complete();
        });
      });
    });
  }
}
