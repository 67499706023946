import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '@app/shared/shared.module';
import { ForgotpasswdComponent } from './forgotpasswd/forgotpasswd.component';
import { RecoverComponent } from './recover/recover.component';
import { SignupComponent } from './signup/signup.component';
import { NgxMasonryModule } from 'ngx-masonry';

@NgModule({
  declarations: [LoginComponent, ForgotpasswdComponent, RecoverComponent, SignupComponent],
  imports: [
    CommonModule,
    FormsModule,
	NgbModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMasonryModule,
    AuthenticationRoutingModule
  ]
})
export class AuthenticationModule { }
