import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, isEmpty } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  apiUrl: string = environment.apiUrl;
  apiUrlV2: string = environment.apiUrlV2;

  private readonly _account = new BehaviorSubject<any>({});
  public account$ = this._account.asObservable();

  private params: any = {
    per_page: 200,
    page: 1
  };

  public perClinicPrice: number = 380;
  public perClinicProrateDaily: number = 13;

  public storage10gbPrice: number = 60;
  public storage10gbPriceProrate: number = 2;

  public active: boolean = true;
  public deactivated: boolean = false;
  public canChangePlan: boolean = false;
  public reactivationFee: number = 2500;
  public canUploadFiles: boolean = true;

  constructor(private http: HttpClient) {
    // this.getAccount();
  }

  getInvoices(params) {
    return this.http.get(`${this.apiUrl}/subscription_invoices`, { params: params });
  }

  getAccount() {
    return this.http.get(`${this.apiUrl}/account`).subscribe((record: any) => {
      if (record && record.id) {
        const subscription: any = record.subscriptions.find((sub: any) => sub.plan_id < 100);
        if (record.status === 0) {
          this.deactivated = true;
          record.deactivated = true;
        }
        // initially set the account as active
        record.active = true;
        this.active = true;

        record.subscription = subscription;
        // delete record.subscriptions;
        // if cancelled set account as disabled and active to false
        if (subscription && subscription.canceled_at &&  subscription.canceled_at !== null) {
          record.active = false;
          this.active = false;
        }

        //check if can still upload files
        if (record.account_credits) {
          this.canUploadFiles = record.account_credits.do_spaces_used <= record.account_credits.do_spaces_allowed;
        }

        this._account.next(record);
      }
    });
  }

  updatePaymentSchedule(payload) {
    return this.http.post(`${this.apiUrl}/account`, payload);
  }

  updateAccount(newData: any) {
    return this.http.put(`${this.apiUrl}/account`, newData).pipe(
      tap((record: any) => {
        if (record && record.id) {
          this._account.next(record);
        }
      })
    );
  }

  getDaysToExpiry(): number {
    const account: any = this._account.getValue();
    if (!account || !account.id) {
      return;
    }
    const today = new Date();
    const expiry = new Date(account.subscription.ends_at);

    // To calculate the time difference of two dates
    const diffInTime = expiry.getTime() - today.getTime();
    // To calculate the no. of days between two dates
    const diffIndays = diffInTime / (1000 * 3600 * 24);
    return Math.floor(diffIndays);
  }

  getPerClinicProrate(): number {
    const days: number = this.getDaysToExpiry();
    return this.perClinicProrateDaily * days;
  }

  getStorate10gbProrate(): number {
    const days: number = this.getDaysToExpiry();
    return this.storage10gbPriceProrate * days;
  }

  addStorageForTrialAccount(payload) {
    return this.http.post(`${this.apiUrl}/account/additional-storage`, payload);
  }
}
