import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';

import { tap, map, catchError, switchMap, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Associate, CrudListResponses, CrudSuccessResponse } from '@app/shared/models';
import swal from 'sweetalert2';
import { GetAPIResponse } from '@app/shared/models/crud-responses';

@Injectable({
  providedIn: 'root'
})
export class AssociatesService {
  url = environment.acctUrl + '/associates';
  apiUrl = environment.apiUrlV2 + '/accounts/associates';
  urlLaboratory = environment.apiUrlV2 + '/accounts/laboratories/associate';

  private readonly _associates = new BehaviorSubject<GetAPIResponse>({});
  public associates = this._associates.asObservable();

  params: any = {
    limit: 100,
    page: 1,
    sortField: 'added_dt',
    sortDirection: 'desc'
  };

  constructor(private http: HttpClient) {
    // this.getInitRecords(this.params).subscribe();
  }
  setParams(params: any) {
    this.params = params;
  }

  refreshList() {
    this._associates.next(null);
    this.getInitRecords(this.params).subscribe();
  }

  getAll(): Observable<any> {
    const params: any = { all: 1 };
    return this.http.get(`${this.url}`, { params: params });
  }
  getActiveAssociate(): Observable<any> {
    return this.http.get(`${this.apiUrl}/active-associates`);
  }

  getInitRecords(params: any) {
    return this.http.get(`${this.apiUrl}`, { params: params }).pipe(
      tap((data: GetAPIResponse) => {
        this._associates.next(data);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  get(id: number): Observable<Associate> {
    const associates = this._associates.getValue();
    if (associates && associates.data?.length > 0) {
      const associate = associates.data?.find((row) => row.id == id) || undefined;

      if (!associate) {
        return this.http.get(`${this.apiUrl}/${id}`).pipe(
          tap(
            (data: Associate) => {
              return of(data);
            },
            catchError((error) => {
              return throwError(error);
            })
          )
        );
      } else {
        return of(associate);
      }
    } else {
      return this.http.get(`${this.apiUrl}/${id}`).pipe(
        tap(
          (data: Associate) => {
            return of(data);
          },
          catchError((error) => {
            return throwError(error);
          })
        )
      );
    }
  }

  create(data: any): Observable<Associate> {
    return this.http.post(`${this.apiUrl}`, data).pipe(
      tap(
        (res: any) => {
          if (res.id < 0) {
            return of({});
          }
          data.id = res.id;
          if (res.accountId) {
            data.account_id = res.accountId;
          }
          if (res.status) {
            data.status = res.status;
          }
          if (res.loginId) {
            data.login_id = res.loginId;
          }
          const associates = this._associates.getValue();
          associates.data.push(data);
          this._associates.next(associates);
          // this._associates.push(<Associate>data);
          return of(<Associate>data);
        },
        (error) => {
          swal({
            title: 'Save error!',
            text: error.error.error,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }
  update(id: number, data: any): Observable<Associate> {
    return this.http.put(`${this.apiUrl}/${id}`, data).pipe(
      tap(
        (res: any) => {
          const associates = this._associates.getValue();
          let associate = associates.data?.find((row) => row.id == id);
          if (associate) {
            associate = <Associate>Object.assign(associate, data);
          }
        },
        (error) => {
          swal({
            title: 'Update error!',
            text: error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }

  activate(id: number) {
    const associates = this._associates.getValue();
    let associate = associates.data?.find((row) => row.id == id);
    let data = { status: associate.status == 0 ? 1 : 0, account_id: associate.account_id };
    if (data.status == 1) {
      return this.http.put(`${this.apiUrl}/activate/${id}`, data).pipe(
        tap((data: Associate) => {
          associate = <Associate>Object.assign(associate, data);
        })
      );
    } else {
      return this.http.put(`${this.apiUrl}/deactivate/${id}`, data).pipe(
        tap((data: Associate) => {
          associate = <Associate>Object.assign(associate, data);
        })
      );
    }
  }

  getClinics(id: number) {
    return this.http.get(`${this.apiUrl}/${id}/clinics`);
  }

  getLaboratories(id: number) {
    return this.http.get(`${this.urlLaboratory}/${id}`);
  }

  delete(id: number): Observable<boolean> {
    // empty data to use the put method
    let data = '';
    return this.http.put(`${this.apiUrl}/delete/${id}`, data).pipe(
      tap((data) => {
        const associates = this._associates.getValue();

        const assIdx: number = associates.data?.findIndex((row) => row.id == id);
        associates.data?.splice(assIdx, 1);
      }),
      map(() => true),
      catchError((error) => {
        swal({
          title: 'Delete error!',
          text: error.error.message,
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6'
        });
        return throwError(error);
      })
    );
  }
}
