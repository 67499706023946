<div class="assessment">
    <h3 class="text-center mt-4 mb-1">COVID-19 PPDSI SELF-ASSESSMENT FORM</h3>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>Assessment</th>
                <th class="text-center">PATIENT</th>
                <th class="text-center">GUARDIAN</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    Body Temperature (&deg;C)
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.temp > 0"> {{ assessJSON.temp }} </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_temp > 0"> {{ assessJSON.g_temp }} </label>
                </td>
            </tr>

            <tr>
                <td>
                    Flu-like symptoms present (fever, cough, headache, shortness of breath, general malaise, diarrhea)
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_f==='1'"> YES </label>
                    <label *ngIf="assessJSON.cv_f==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_f==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_cv_f==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    1. Was the patient or any individual living with the patient or family relative diagnosed with
                    covid19?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_d==='1'"> YES </label>
                    <label *ngIf="assessJSON.cv_d==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_d==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_cv_d==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    Date of positive diagnosis
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_d_dtp"> {{ assessJSON.cv_d_dtp | date: 'mediumDate' }} </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_d_dtp"> {{ assessJSON.g_cv_d_dtp | date: 'mediumDate' }} </label>
                </td>
            </tr>
            <tr>
                <td>
                    Date of negative diagnosis
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_d_dtn"> {{ assessJSON.cv_d_dtn | date: 'mediumDate' }} </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_d_dtn"> {{ assessJSON.g_cv_d_dtn | date: 'mediumDate' }} </label>
                </td>
            </tr>

            <tr>
                <td>
                    2. Did you travel in another country recently in the past month?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_t==='1'"> YES </label>
                    <label *ngIf="assessJSON.cv_t==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_t==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_cv_t==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    If YES, where did you go?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_t_loc"> {{ assessJSON.cv_t_loc }} </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_t_loc"> {{ assessJSON.g_cv_t_loc }} </label>
                </td>
            </tr>
            <tr>
                <td>
                    If YES, date of arrival
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_t_dt"> {{ assessJSON.cv_t_dt | date: 'mediumDate' }} </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_t_dt"> {{ assessJSON.g_cv_t_dt | date: 'mediumDate' }} </label>
                </td>
            </tr>

            <tr>
                <td>
                    3. Do you live in an area with a high number of cases of covid19?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_hn==='1'"> YES </label>
                    <label *ngIf="assessJSON.cv_hn==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_hn==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_cv_hn==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    4. Did you have cough or flu-like symptoms in the past month?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_flu==='1'"> YES </label>
                    <label *ngIf="assessJSON.cv_flu==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_flu==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_cv_flu==='0'"> NO </label>
                </td>
            </tr>

            <tr>
                <td>
                    5. Did you attend a mass gathering, a reunion or a party in the past month?
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.cv_mass==='1'"> YES </label>
                    <label *ngIf="assessJSON.cv_mass==='0'"> NO </label>
                </td>
                <td class="text-center">
                    <label *ngIf="assessJSON.g_cv_mass==='1'"> YES </label>
                    <label *ngIf="assessJSON.g_cv_mass==='0'"> NO </label>
                </td>
            </tr>

        </tbody>
    </table>

    <h4 class="text-uppercase text-center mt-4 mb-1">Oath of Affirmation</h4>
    <p>
        I <b><u> {{ [patient.firstname, patient.middlename, patient.lastname].join(' ') }} </u></b>, do solemnly swear
        under oath states that my declaration in the Patient's Form are True and that I understand that should it be
        discovered to be false later on, I will be subjected to Criminal Prosecution and subject my estate for
        claims against it for damages.
    </p>

    <div class="text-center">
        <div class="signagure"> <img src="{{ signatureUrl }}" /> </div>
        <div> <label class="control-label text-center text-uppercase">Patient Signature</label> </div>
    </div>

    <div class="text-center" *ngIf="assessment.guardianSignatureUrl">
        <div class="signagure">
            <img src="{{ guardianSignatureUrl }}" />
            <h1 class="text-center" *ngIf="assessJSON.guardian_name">
                {{ assessJSON.guardian_name }}
            </h1>
        </div>
        <div> <label class="control-label text-center text-uppercase">Guardian Name and Signature</label> </div>
    </div>


    <h4 class="text-center m-4"> <b> RA11332 Law on Reporting of Communicable Diseases </b> </h4>
    <p>
        <b>Section 9.d</b> "Non-cooperation of persons or entities that should report or respond to notifiable
        diseases or health events of public concern"
    </p>
    <p>
        <b>Section 10</b> "fine of 20,000.00 to 50,000.00 or be imprisoned for 1 to 6 months or both"
    </p>

    <div class="notary mt-4">
        SUBSCRIBE AND SWORN to before my Notary Public affiant exhibiting his/her government issued ID No.
        ___________________ issued on ___________________ issued at ____________________________________________. Done
        on the day of __________________, _____ in __________________________.
    </div>
</div>
