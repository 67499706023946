import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@app/shared/auth/auth.service';
import { DEVICE_ID_KEY } from '@app/shared/constants/settings.constants';
import { CacheService } from '@app/shared/services/helpers/cache.service';
import { environment } from '@env/environment';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('f', { static: true }) loginForm: NgForm;

  alert: any = {};

  masonryOptions: NgxMasonryOptions = {
    itemSelector: '.masonry-item',
    columnWidth: 200,
    fitWidth: true
  };
  masonryItems: any = [];

  submitted: boolean = false;

  email: string;
  password: string;
  privacy: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private http: HttpClient,
    private cacheService: CacheService
  ) {}

  ngOnInit() {
    this.closeAlert();
    this.http.get(`/assets/json/loginshop.json`).subscribe((resp) => {
      this.masonryItems = resp;
    });
    this.cacheService.clearCache();
  }

  closeAlert() {
    this.alert = {};
  }

  onSubmit() {
    if (!this.email || !this.password) {
      this.alert = { error: 'Invalid login' };
      return;
    }
    if (!this.privacy) {
      this.alert = { error: 'Please read and accept the MyMedsPH Privacy Policy.' };
      return;
    }

    this.submitted = true;
    const deviceId = localStorage.getItem(DEVICE_ID_KEY);
    const formData = this.loginForm.value;
    formData.deviceId = deviceId;
    this.auth.login(this.loginForm.value).subscribe(
      (data) => {
        this.auth.authenticationState.next(true);
        this.auth.getLoginProfile(true);
        this.cacheService.cacheData();
        localStorage.setItem(DEVICE_ID_KEY, data['deviceId']);
        if (this.auth.isAdmin()) {
          this.router.navigate(['manage/settings/my-account']);
        } else {
          this.router.navigate(['landing']);
        }
        this.submitted = false;
      },
      (error) => {
        this.submitted = false;
        this.alert = error.error;
      }
    );
  }

  onForgotPassword() {
    this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
  }

  signup() {
    this.router.navigate(['signup'], { relativeTo: this.route.parent });
  }
  sendOTP() {
    // create send otp
  }
}
