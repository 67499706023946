<form [formGroup]="formData" (ngSubmit)="onSubmit()">
    <section id="login">
        <div class="container-fluid">
            <!-- <div class="container-fluid gradient-purple-love"> -->
            <div class="row full-height-vh">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="card width-800">
                        <div class="card-body">
                            <div class="card-title">
                                <h3 class="text-center m-3">
                                    <img alt="element 06" class="mb-1" src="assets/favicons/android-chrome-192x192.png"
                                        width="60" /> Dental App Account Registration</h3>
                            </div>

                            <div class="card-block payment-sched" *ngIf="!showForm">


                                <fieldset class="mt-3">
                                    <legend>Choose Your Subscription Payment Schedule</legend>
                                    <div class="form-group mb-3">
                                        <div class="plans mt-1">
                                            <h4 class="plan">
                                                <label for="monthly">
                                                    <input type="radio" id="monthly" name="plan_id"
                                                        formControlName="plan_id" value="1">
                                                    Pay Monthly
                                                    @ {{ subscriptionPlans[0].price | currency: ' ' }}
                                                </label>
                                            </h4>

                                            <h4 class="plan">
                                                <label for="quarterly">
                                                    <input type="radio" id="quarterly" name="plan_id"
                                                        formControlName="plan_id" value="2">
                                                    Pay Quarterly
                                                    @ {{ subscriptionPlans[1].price | currency: ' ' }}
                                                    <span class="savings text-success">
                                                        Save 5%
                                                    </span>
                                                </label>
                                            </h4>

                                            <h4 class="plan">
                                                <label for="yearly">
                                                    <input type="radio" id="yearly" name="plan_id"
                                                        formControlName="plan_id" value="3">
                                                    Pay Yearly
                                                    @ {{ subscriptionPlans[2].price | currency: ' ' }}
                                                    <span class="savings text-success">
                                                        Save 15%
                                                    </span>
                                                </label>
                                            </h4>

                                        </div>
                                        <!-- <select formControlName="plan_id" class="form-control">
                                            <option *ngFor="let plan of subscriptionPlans" [value]="plan.id">
                                                {{ plan.name }} @ {{ plan.price | currency: ' ' }}
                                            </option>
                                        </select> -->
                                        <!-- <control-messages [submitted]="submitted"
                                            [control]="formData.controls.plan_id">
                                        </control-messages> -->
                                    </div>

                                    <div class="features">
                                        <h5 class="title">
                                            With your subscription, you will get:
                                        </h5>
                                        <ul>
                                            <li>1 Account</li>
                                            <li>1 Clinic</li>
                                            <li>Unlimited associate records</li>
                                            <li>Unlimited staff records</li>
                                            <li>Unlimited patient records</li>
                                            <li>Unlimited appointments</li>
                                            <li>Unlimited recalls</li>
                                            <li>Unlimited charts</li>
                                            <li>Unlimited patient transactions</li>
                                            <li>Up to 10GB cloud storage for uploads</li>
                                        </ul>
                                        <div class="addons">
                                            Addon:
                                            <ul>
                                                <li> Additional clinic @ 380.00 / month </li>
                                                <li> Additional cloud storage for uploads (price will vary base from
                                                    hosting provider)</li>
                                            </ul>
                                        </div>
                                    </div>


                                </fieldset>

                                <div class="row actions">

                                    <div class="col-md-12 text-center">
                                        <button class="btn btn-lg btn-primary btn-raised m-2"
                                            [disabled]="submitted" type="submit">
                                            <i class="fas fa-check" *ngIf="!submitted"></i>
                                            <i class="fas fa-spinner fa-spin" *ngIf="submitted"></i> Register
                                            and Join MyMedsPH
                                        </button>
                                        <div class="m-2" align="center">
                                            <a (click)="showForm=true" class="text-center">Go back</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="card-block" *ngIf="showForm">

                                <div class="row">
                                    <div class="col-md-6">
                                        <fieldset>
                                            <legend>Personal Info</legend>
                                            <div class="form-group">
                                                <label for="">Firstname</label>
                                                <input type="text" formControlName="firstname" class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.firstname">
                                                </control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Lastname</label>
                                                <input type="text" formControlName="lastname" class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.lastname">
                                                </control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Email</label>
                                                <input type="email" formControlName="email" class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.email">
                                                </control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Mobile</label>
                                                <input type="text" formControlName="mobile" class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.mobile">
                                                </control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label for="">License No</label>
                                                <input type="text" formControlName="license_no" class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.license_no">
                                                </control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label for="">PTR No</label>
                                                <input type="text" formControlName="ptr_no" class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.ptr_no">
                                                </control-messages>
                                            </div>
                                        </fieldset>

                                    </div>
                                    <div class="col-md-6">
                                        <fieldset>
                                            <legend>Clinic Info</legend>
                                            <div class="form-group">
                                                <label for="">Clinic Name</label>
                                                <input type="text" formControlName="clinic_name" class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.clinic_name">
                                                </control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Specialization</label>
                                                <input type="text" formControlName="specialization"
                                                    class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.specialization">
                                                </control-messages>
                                            </div>

                                            <div class="form-group">
                                                <label for="">Contact</label>
                                                <input type="text" formControlName="contact" class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.contact">
                                                </control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Address</label>
                                                <input type="text" formControlName="address" class="form-control">
                                                <control-messages [submitted]="submitted"
                                                    [control]="formData.controls.address">
                                                </control-messages>
                                            </div>
                                        </fieldset>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mt-3" align="center">
                                            <a (click)="backToLogin()" class="text-center">Go to
                                                Login</a>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <button class="btn btn-lg btn-primary btn-block btn-raised" (click)="next()"
                                            type="button">
                                            <i class="fas fa-arrow-right"></i> Next
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>