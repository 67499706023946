<section id="login">
  <div class="container-fluid">
    <div class="row full-height-vh">

      <div class="col-md-6 col-sm-12 d-flex align-items-center justify-content-center login-block">
        <div class="card gradient-indigo-purple text-center width-400">
          <div class="card-img overlap">
            <div class="img-container">
              <img alt="MyMedsPH Logo" class="mb-1" src="/assets/img/mmlogo.png" width="150" />
            </div>
            <!-- <img alt="element 06" class="mb-1" src="assets/favicons/android-chrome-192x192.png"
							width="190" /> -->
          </div>
          <div class="card-body">
            <div class="card-block">
              <h2 class="white">Dental App Login</h2>

              <ngb-alert [dismissible]="false" *ngIf="alert && alert.error" (close)="alert = {}">
                {{ alert.error }}
              </ngb-alert>

              <form novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
                <div class="form-group">
                  <div class="col-md-12">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Email"
                      [(ngModel)]="email"
                      required
                      email
                      autocomplete="on"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <div class="col-md-12">
                    <input
                      type="password"
                      class="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      [(ngModel)]="password"
                      required
                      autocomplete="on"
                    />
                  </div>
                </div>

                <div class="form-group mb-3">
                  <div class="col-md-12">
                    <div class="custom-control custom-checkbox m-0">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [(ngModel)]="privacy"
                        name="privacy"
                        id="privacy"
                      />
                      <label class="custom-control-label" for="privacy">
                        I have read and understood the
                        <a target="_blank" href="https://mymedsph.com/privacy">MyMedsPH Privacy Policy</a>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-info btn-block btn-raised">
                      <span *ngIf="!submitted"> <i class="fas fa-check"></i> Login </span>
                      <span *ngIf="submitted"
                        ><i class="fas fa-circle-notch fa-spin"></i> Logging in, please wait...
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer">
            <!-- <div class="form-group">
							<div class="col-md-12">
								<button type="submit" class="btn btn-info btn-block btn-raised" (click)="signup()">
									Sign Up for Free Trial
								</button>
							</div>
						</div> -->
            <div class="" align="center">
              <a (click)="onForgotPassword()" class="white text-center">Recover Password</a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 ad-space d-flex flex-column align-items-center justify-content-center">
        <div class="shop-title">
            <a href="https://apdcpda.com/">
                <img alt="MyMedsPH Logo" id="img-content" class="mb-1" src="/assets/img/APDC.jpg"/>
            </a>
        </div>

        <div class="shop-details">
            <a href="https://apdcpda.com/" class="register-btn btn btn-raised px-4">
                <span>Register here</span>
            </a>
        </div>
      </div>

      <!-- <div class="col-md-6 col-sm-12 ad-space">

                <div class="shop-title">
                    <a href="https://shop.mymedsph.com/">
                        <img alt="MyMedsPH Logo" class="mb-1" src="/assets/img/shop-logo.png" width="350" />
                    </a>
                </div>

                <div class="ads">

                    <ngx-masonry [options]="masonryOptions">
                        <div ngxMasonryItem class="masonry-item" *ngFor="let item of masonryItems">
                            <a [href]="item.link" target="_blank">
                                <img [src]="item.image_url" alt="">
                            </a>
                        </div>
                    </ngx-masonry>

                </div>


            </div> -->
    </div>
  </div>
</section>
