import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { tap, map, catchError, switchMap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { CrudListResponses, AccountSetting } from '@app/shared/models';
import swal from 'sweetalert2';
import { FilterPipe } from 'ngx-filter-pipe';
import { AuthService } from '@app/shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  apiUrlV2 = environment.apiUrlV2+ '/accounts/account-settings';


  settings: AccountSetting[];

  constructor(private http: HttpClient, private filterPipe: FilterPipe, private authSvc: AuthService) {
    // this.getInitRecords({}).subscribe();
  }

  getInitRecords(params) {
    return this.http.get(`${this.apiUrlV2}`, { params: params }).pipe(
      tap(
        (response:any) => {
        //   this.settings = <AccountSetting[]>response.rows;
          this.settings = <AccountSetting[]>response.data;
        },
        (error) => {
          return of(null);
        }
      )
    );
  }

  getSettings(associate?: boolean): Observable<any> {
    if (associate) {
      if (this.settings) {
        return of(
          this.settings.filter((row: any) => {
            return row.associateId > 0;
          })
        );
      }
      return this.getInitRecords({ associate_id: this.authSvc.user.data.id });
    } else {
      if (this.settings) {
        return of(this.settings);
      }
      return this.getInitRecords({});
    }
  }

  getSetting(associate?: boolean, key?: string): Observable<any> {
    if (associate) {
      return this.getInitRecords({ associate_id: this.authSvc.user.data.id, set_key: key });
    }
  }

  get(key: string) {
    if (!this.settings) {
      return null;
    }
    let value = this.settings.find((row) => row.setKey == key);
    if (value) {
      return value.setValue;
    }
    // value = value.replace(/&#09;/g, '\t');
    // value = value.replace(/&nbsp;&nbsp;&nbsp;&nbsp;/g, '\t');
    return null;
  }

  getKiosk() {
    return this.getInitRecords({});
  }

  save(data: any[], associate?: boolean) {
    let set_str = JSON.stringify(data);
    // set_str = set_str.replace(/\\t/g, '&#09;');
    set_str = set_str.replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    let settings: any = { settings: set_str };
    settings.account_id = this.authSvc.getUserData('account_id');
    if (associate) {
      settings.associate_id = this.authSvc.user.data.id;
    }
    return this.http.post(`${this.apiUrlV2}`, settings).pipe(
      tap(
        (res: any) => {
          this.getInitRecords(associate).subscribe();
        },
        (error) => {
          swal({
            title: 'Save error!',
            text: error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }
}
