import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { tap, catchError, map, share, mapTo } from 'rxjs/operators';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { LocalStorageService } from 'angular-web-storage';
import { JwtHelperService } from './jwt-helper.service';

import { environment } from '@env/environment';
import { Clinic, Laboratory } from '../models';

import swal from 'sweetalert2';
import { async } from 'q';
import { Router } from '@angular/router';
import { APPOINTMENT_FILTER, DEVICE_ID_KEY } from '../constants/settings.constants';

const TOKEN_KEY = 'access_token';
const REF_TOKEN_KEY = 'refresh_token';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _loginProfile = new BehaviorSubject<any>(null);
  public loginProfile$ = this._loginProfile.asObservable();
  gettingLoginProfile: boolean = false;

  url = environment.url;
  apiUrl = environment.apiUrlV2;
  user = null;
  loading: any;
  profile: any;
  patientFormLocked: any = false;
  patientEntryKioskMode: any = false;
  team: any;

  authenticationState = new BehaviorSubject(false);

  @Output() labChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() clinicChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private helper: JwtHelperService,
    private storage: LocalStorageService,
    private router: Router
  ) {
    this.checkToken();
    this.setUser(this.storage.get(TOKEN_KEY));
    this.checkPtFormLockStatus();
  }

  checkToken() {
    let token = this.storage.get(TOKEN_KEY);
    if (token) {
      let decoded = this.helper.decodeToken(token);
      let isExpired = this.helper.isTokenExpired(token);
      this.authenticationState.next(true);
      this.user = decoded;
      if (isExpired) {
        this.refresh(true);
      }
    } else {
      this.authenticationState.next(false);
    }
  }

  login(credentials, noSetToken?: boolean) {
    if (!credentials.password || !credentials.email) {
      return;
    }
    return this.http.post(`${this.apiUrl}/auth/login`, credentials).pipe(
      tap((data) => {
        if (data && data[TOKEN_KEY]) {
          if (noSetToken) {
          } else {
            this.setUserTokens(data);
          }
        }
      })
    );
  }

  refreshToken() {
    // let token = this.storage.get(REF_TOKEN_KEY);
    const token = this.storage.get(REF_TOKEN_KEY);
    let data: any = {
      token: token
    };
    const clinic_id = this.getUserData('clinic_id');
    if (clinic_id) {
      data.clinic_id = clinic_id;
    }
    if (this.patientEntryKioskMode || (this.user && this.user.data && this.user.data.kiosk)) {
      data.kiosk = 1;
    }

    return this.http.post(`${this.apiUrl}/auth/refresh`, data).pipe(
      tap((data) => {
        if (data && data[TOKEN_KEY]) {
          this.setUserTokens(data);
        }
      }),
      catchError((error) => {
        this.logout(true);
        return throwError(error);
      })
    );
  }

  refresh(showAlert?: boolean) {
    return this.refreshToken().subscribe(
      (data) => {
        return this.authenticationState.next(true);
      },
      (error) => {
        return this.logout(showAlert);
      }
    );
  }

  lockApp() {
    this.storage.set('SWACT_X', 1);
  }

  unlockApp() {
    this.storage.remove('SWACT_X');
  }

  isAppLocked() {
    return this.storage.get('SWACT_X');
  }

  getTeam() {
    const token = this.storage.get(TOKEN_KEY);
    const clinic_id = this.getUserData('clinic_id');
    if (!clinic_id) {
      return;
    }
    return this.http.get(`${this.apiUrl}/auth/team`);
    // return this.http.post(`${this.url}/auth/team`, { token: token, clinic_id: clinic_id });
  }

  setUserTokens(data: any) {
    this.setUser(data[TOKEN_KEY]);
    this.storage.set(TOKEN_KEY, data[TOKEN_KEY]);
    this.storage.set(REF_TOKEN_KEY, data[REF_TOKEN_KEY]);
  }

  getToken() {
    return this.storage.get(TOKEN_KEY);
  }

  logout(showAlert?: boolean) {
    if (showAlert) {
      swal({
        type: 'warning',
        title: 'Login expired. Please login again.'
      }).then((value) => {
        this.doLogout();
      });
    } else {
      //   this.storage.remove('account_logo');
      //   this.storage.remove('digital_signature');
      this.doLogout();
      return this.authenticationState.next(false);
    }
  }

  private doLogout() {
    this.patientFormLocked = false;
    this.clearLocalStorageExcept([DEVICE_ID_KEY, APPOINTMENT_FILTER]);
    this.router.navigate(['auth/login']);
    this.unlockApp();
    setTimeout(() => {
      window.location.reload();
    }, 1);
  }

  isAuthenticated() {
    // this.checkToken();
    return this.authenticationState.value;
  }

  startClinic(clinic: Clinic, kioskMode?: boolean): Observable<boolean> {
    const token = this.storage.get(TOKEN_KEY);
    let data: any = {
      token: token,
      clinic_id: clinic.id
    };
    if (kioskMode) {
      data.kiosk = 1;
    }
    return this.http.post(`${this.apiUrl}/auth/clinics`, data).pipe(
      tap((data) => {
        if (data && data[TOKEN_KEY]) {
          this.setUserTokens(data);
          this.clinicChanged.emit(this.getUserData('clinic_id'));
        }
      }),
      map((data) => true),
      catchError((error) => {
        swal(error.error, '', 'error');
        return throwError(error);
      })
    );
  }

  startLaboratory(lab: Laboratory): Observable<boolean> {
    let token = this.storage.get(TOKEN_KEY);
    return this.http.post(`${this.apiUrl}/auth/laboratories`, { token: token, laboratory_id: lab.id }).pipe(
      tap((data) => {
        if (data && data[TOKEN_KEY]) {
          this.storage.set(TOKEN_KEY, data[TOKEN_KEY]);
          this.storage.set(REF_TOKEN_KEY, data[REF_TOKEN_KEY]);
          this.setUser(data[TOKEN_KEY]);
          this.labChanged.emit(this.getUserData('laboratory_id'));
        }
      }),
      map((data) => true),
      catchError((error) => {
        swal(error.error, '', 'error');
        return throwError(error);
      })
    );
  }

  isAdmin() {
    return this.user.is_admin;
  }

  isAssociate() {
    if (parseInt(this.user.data.login_type) < 16) {
      return false;
    }
    return true;
  }

  isStaff() {
    if (parseInt(this.user.data.login_type) < 16) {
      return true;
    }
    return false;
  }

  private setUser(token) {
    if (!token) return;
    this.user = this.helper.decodeToken(token);
    if (parseInt(this.user.data.is_admin) == 1) {
      this.user.is_admin = true;
    } else {
      this.user.is_admin = false;
    }
  }

  getLoginProfile(reload?: boolean) {
    if (this.gettingLoginProfile || (this.profile && this.profile.id && !reload)) {
      return;
    }

    this.gettingLoginProfile = true;
    this.profile = null;
    this._loginProfile.next(null);

    let profileUrl: string = `${this.apiUrl}/accounts/staff/${this.getUserData('id')}`;
    if (parseInt(this.getUserData('login_type')) == 16) {
      profileUrl = `${this.apiUrl}/accounts/associates/${this.getUserData('id')}`;
    }
    this.http.get(profileUrl).subscribe(
      (data: any) => {
        this.gettingLoginProfile = false;
        this.profile = data;
        this._loginProfile.next(data);
      },
      (_) => {
        this.gettingLoginProfile = false;
      }
    );
  }

  updatePassword(data: any) {
    return this.http.post(`${this.apiUrl}/auth/password`, data).pipe(map((data) => true));
  }

  forgotPassword(email: string) {
    let url = window.location.origin + '/auth/recover';
    return this.http.post(`${this.apiUrl}/auth/forgot-password`, { email: email, url: url });
  }

  recoverPassword(data: any) {
    return this.http.post(`${this.apiUrl}/auth/recover-password`, data);
  }

  getUserData(key: string) {
    if (this.user) {
      return this.user.data[key];
    }
    return null;
  }

  getMyClinics() {
    return this.http.get(`${this.apiUrl}/auth/clinics`);
  }

  getMyLaboratories() {
    return this.http.get(`${this.apiUrl}/auth/laboratories`);
  }

  register(data) {
    return this.http.post(`${this.apiUrl}/auth/register`, data);
  }

  getPlans() {
    return this.http.get(`${this.apiUrl}/auth/register`);
  }

  lockPatientForm(url: string, lockKey: string) {
    const key = this.digestMessage(lockKey);
    const lockData: any = { url: url, key: key };
    this.patientFormLocked = lockData;
    this.storage.set('PTXFRM', lockData);
  }

  unlockPatientForm(lockKey) {
    const key = this.digestMessage(lockKey);
    if (key == this.patientFormLocked.key) {
      this.patientFormLocked = false;
      this.storage.remove('PTXFRM');
      return true;
    } else {
      return false;
    }
  }

  checkPtFormLockStatus() {
    const ptStat = this.storage.get('PTXFRM');
    if (ptStat) {
      this.patientFormLocked = ptStat;
    }
    if (this.user && this.user.data && this.user.data.kiosk) {
      this.patientEntryKioskMode = true;
    }
    // console.log(this.user);
  }

  digestMessage(s) {
    var a = 1,
      c = 0,
      h,
      o;
    if (s) {
      a = 0;
      for (h = s.length - 1; h >= 0; h--) {
        o = s.charCodeAt(h);
        a = ((a << 6) & 268435455) + o + (o << 14);
        c = a & 266338304;
        a = c !== 0 ? a ^ (c >> 21) : a;
      }
    }
    return String(a);
  }

  updateEmailData(data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/auth/update-email`, data).pipe(map((data) => true));
  }

  clearLocalStorageExcept(keysToKeep: string[]) {
    let keysToRemove = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!keysToKeep.includes(key)) {
        keysToRemove.push(key);
      }
    }

    for (const key of keysToRemove) {
      localStorage.removeItem(key);
    }
  }
}
