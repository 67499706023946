import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule)
  },
  {
    path: 'laboratory',
    loadChildren: () => import('./laboratory/laboratory.module').then((m) => m.LaboratoryModule)
  },
  {
    path: 'manage',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule)
  },
  {
    path: 'kiosks',
    loadChildren: () => import('./kiosks/kiosks.module').then((m) => m.KiosksModule)
  },
  {
    path: 'prints',
    loadChildren: () => import('./shared/components/prints/prints/prints.module').then((m) => m.PrintsModule)
  },
  {
    path: '',
    loadChildren: () => import('./practice/practice.module').then((m) => m.PracticeModule)
  },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
