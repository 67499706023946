import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class PeriodontalScreeningService extends BaseService {

	constructor(
		http: HttpClient
	) {
		super(http);
		// this.url =  environment.mstfUrl + '/recall_periodontal';
		this.url = environment.apiUrlV2 + '/masterfile/recall-periodontal-screening';
		// this.getInitRecords().subscribe();
	}
}
