import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { tap, catchError, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(public auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = this.addAuthToken(request);

        return next.handle(request).pipe(catchError(error => {

            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
            } else {
                return throwError(error);
            }

        }))

    }

    handle401Error(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.refreshTokenInProgress) {

            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);

            return this.auth.refreshToken().pipe(
                switchMap((token: any) => {
                    this.refreshTokenInProgress = false;
                    this.refreshTokenSubject.next(true);
                    return next.handle(this.addAuthToken(request));
                })
            )

        } else {

            return this.refreshTokenSubject.pipe(
                filter(value => value != null),
                take(1),
                switchMap(value => {
                    return next.handle(this.addAuthToken(request));
                })
            )

        }

    }

    addAuthToken(request: HttpRequest<any>) {
        if (!this.auth) {
            return request;
        }
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getToken()}`
            }
        });

    }

}
