const BASEURL = 'https://backend.staging.mymedsph.com';

export const environment = {
  production: true,
  offline: false,
  url: BASEURL,
  acctUrl: BASEURL + '/acct',
  mstfUrl: BASEURL + '/mstf',
  prctUrl: BASEURL + '/prct',
  sysUrl: BASEURL + '/sys',
  rptUrl: BASEURL + '/rpt',
  apiUrl: 'https://dataapi.staging.mymedsph.com/api/dental',
  apiUrlV2: 'https://dataapi.staging.mymedsph.com/api',
  clientID: 'AT2X1ZXJlMWdP7xRm9FI_dH9NGvgvcffalymxCL6lpN4YQirtX5lxcB7teqGna8HKjr06Fri0ebIWSZo',
  // payPK: 'pk_live_yPrHjFi2tmkPjLRq79egysX5',
  payPK: 'pk_test_dFV2z9E4C5vhMERYufsPyBFC',
  version: '1.0.0',
  splitAuthorizationKey: 'jkj9a66rgnl2lsagsgv3qqcbrk77famookuq',

  ECHO_CONFIG: {
    broadcaster: 'pusher',
    key: 'MyMedsPHNotif',
    wsHost: 'dataapi.staging.mymedsph.com',
    auth: {},
    authEndpoint: 'https://dataapi.staging.mymedsph.com/api/broadcasting/auth',
    // host: 'https://data.mymedsph.com',
    wsPort: 6002,
    wssPort: 6002,
    disableStats: true,
    forceTLS: true,
    encrypted: true,
    enabledTransports: ['ws', 'wss']
  }
};
