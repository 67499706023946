<router-outlet></router-outlet>
<router-outlet name="print"></router-outlet>

<div class="printing-container">
  <app-print-half-letter-template *ngIf="printSvc.isPrinting && printSvc.template == 'half-letter'">
  </app-print-half-letter-template>
  <app-print-letter-template *ngIf="printSvc.isPrinting && printSvc.template == 'letter'"></app-print-letter-template>
</div>

<div *ngIf="isLoading | async" class="loading-bar"></div>

<ngx-spinner
  bdOpacity="0.5"
  bdColor="rgba(0,0,0,0.35)"
  size="large"
  color="#fff"
  type="ball-scale-pulse"
  [fullScreen]="true"
>
  <p style="color: white">Processing, please wait...</p>
</ngx-spinner>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  name="processing-payment"
  size="large"
  color="#fff"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
  <p style="color: white">Processing payment, please do not close this window or refresh this page...</p>
</ngx-spinner>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  name="printing"
  size="large"
  color="#fff"
  type="ball-spin"
  [fullScreen]="true"
>
  <p style="color: white">Preparing to print, please wait...</p>
</ngx-spinner>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  name="generatingPDF"
  size="large"
  color="#fff"
  type="ball-spin"
  [fullScreen]="true"
>
  <p style="color: white">Generating PDF, please wait...</p>
</ngx-spinner>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  name="processing-mobile-banking"
  size="large"
  color="#fff"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
  <p style="color: white">Processing Mobile Banking, please do not close this window or refresh this page...</p>
</ngx-spinner>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  name="found-mobile-banking-transaction"
  size="large"
  color="#fff"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
  <p style="color: white">Found Mobile Banking Transaction, please do not close this window or refresh this page...</p>
</ngx-spinner>
<!-- <app-print-template id="print-template-container"></app-print-template>
<app-print-template-letter id="print-letter-container"></app-print-template-letter> -->
