import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@app/shared/auth/jwt-helper.service';
import swal from 'sweetalert2';

@Component({
	selector: 'app-recover',
	templateUrl: './recover.component.html',
	styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

	model: any = {};
	token: string;
	showN: boolean = false;

	constructor(
		private auth: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private helper: JwtHelperService,
	) { }

	ngOnInit() {
		this.route.queryParams.subscribe(
			(params) => {
				if (params.tk) {
					this.token = params.tk;
					let isExpired = this.helper.isTokenExpired(params.tk);
					if (isExpired) {
						swal('Link expired.', 'This link is already expired.', 'error');
						this.router.navigate(['/auth/login']);
					}
				} else {
					this.router.navigate(['/auth/login']);
				}
			}
		)
	}

	onSubmit() {

		let decoded: any = this.helper.decodeToken(this.token);
		let isExpired = this.helper.isTokenExpired(this.token);
		if (isExpired) {
			swal('Link expired.', 'This link is already expired.', 'error');
			this.router.navigate(['/auth/login']);
			return;
		}
		if (decoded.data.email != this.model.email) {
			swal('Invalid.', 'Email address is invalid.', 'error');
			// this.router.navigate(['/auth/login']);
			// return;
		}
		let data: any = Object.assign({}, this.model);
		data.token = this.token;
		this.auth.recoverPassword(data).subscribe(
			(data: any) => {
				swal(data, '', 'success');
				this.router.navigate(['/auth/login']);
			}, error => {
				swal(error.error.error, '', 'error');
			})
	}

	showHidePasswd(elid: string) {
		let pass: HTMLElement = document.getElementById(elid) as HTMLElement;
		if (pass.getAttribute('type') == 'password') {
			pass.setAttribute('type', 'text');
		} else {
			pass.setAttribute('type', 'password');
		}
	}

}
