<div class="print-container ql-snow">
  <div class="header ql-editor">
    <div *ngIf="headerHtml">
      <div class="head-details" [innerHtml]="headerHtml"></div>
      <div class="spacer">&nbsp;</div>
      <div class="patient-details font14">
        <div class="flex-left">
          <div><b>Patient: </b> {{ patient.firstname + ' ' + patient.lastname + ' ' + patient.extname}}</div>
          <div><b>Address: </b> {{ patient.address }}</div>
        </div>
        <div class="flex-right">
          <div><b>Age: </b> {{ patient.birthday | age }}</div>
          <div><b>Date: </b> {{ today | date }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="spacer">&nbsp;</div>

    <!-- <router-outlet></router-outlet> -->

    <app-print-recall *ngIf="printService.templatePage == 'recall'"></app-print-recall>
    <app-print-covid-assessment *ngIf="printService.templatePage == 'covid-assessment'"></app-print-covid-assessment>
    <app-print-covid-ppdsi-assessment *ngIf="printService.templatePage == 'covid-ppdsi-assessment'"> </app-print-covid-ppdsi-assessment>
    <app-print-informed-consent *ngIf="printService.templatePage == 'informed-consent'"></app-print-informed-consent>
    <app-ppds-informed-consent-waiver *ngIf="printService.templatePage == 'ppdsi-informed-consent'"> </app-ppds-informed-consent-waiver>
    <app-print-covid-esppbe-assessment *ngIf="printService.templatePage == 'covid-esppbe-assessment'"> </app-print-covid-esppbe-assessment>
    <app-print-covid-doh-assessment *ngIf="printService.templatePage == 'covid-doh-assessment'"> </app-print-covid-doh-assessment>

    <div class="body-details" *ngIf="printService.templatePage == 'genericPrint'" [innerHtml]="htmToDisplay"></div>

    <div class="spacer">&nbsp;</div>
  </div>
  <div class="footer">
    <div class="flex-left">
      <div class="font14" [innerHtml]="bottomLeftHtml">-</div>
    </div>
    <div class="flex-right">
      <div class="font14">
        <div class="signature" *ngIf="signature">
          <img [src]="signature" alt="" />
        </div>
        <div [innerHtml]="bottomRightHtml"></div>
      </div>
    </div>
  </div>
</div>
