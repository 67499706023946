<div id="print-recall-content" *ngIf="recall">
    <h3 align="center" class="text-center">Patient Recall Chart</h3>
    <div class="spacer">&nbsp;</div>

    <table class="table">
        <tbody>
            <tr>
                <td>
                    <div class="form-group">
                        <label for="">Present Medical Condition</label>
                        <h4 class="text-label">{{recall.presentCondition}}</h4>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label for="">Present Medications</label>
                        <h4 class="text-label">{{recall.presentMedications}}</h4>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label for="">Allergies to Medications</label>
                        <h4 class="text-label">{{recall.presentAllergies}}</h4>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="form-group">
                        <label for="">Extraoral Examination</label>
                        <h4 class="text-label">{{recall.extraoralExam}}</h4>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <section *ngIf="hasIntraOralExam" class="intraoral-exam">
        <table class="table">
            <thead>
                <tr>
                    <th colspan="2" class="text-left">
                        <span class="label">Legend: </span>
                        <span class="tooth-status text-uppercase"
                        *ngFor="let stat of (toothStatusSvc.record$ | async)?.data || []; let i = index">
                           <svg height="20" width="20">
                                <circle cx="10" cy="10" r="8" stroke="gray" stroke-width="1" [attr.fill]="stat.color" />
                            </svg>
                            {{stat.name}}
                        </span>
                    </th>
                </tr>
                <tr>
                    <th colspan="2" align="center">STATUS</th>
                </tr>
                <tr>
                    <th align="center">RIGHT</th>
                    <th align="center">LEFT</th>
                </tr>
            </thead>
            <tbody>
                <tr class="vbot">
                    <td align="right">
                        <div class="intraoral-tooth text-center pull-right"
                            *ngFor="let idx of intraOralForm.right_0; let i = index;">
                            <!-- <div class="condition">{{intraOral[idx].legendsStr.join(', ')}}</div> -->
                            <div class="condition">
                                <span [style.color]="tc.cr"
                                    *ngFor="let tc of intraOral[idx].legendsObj">{{tc.cd ? tc.cd : '-'}}</span>
                            </div>
                            <div class="tooth-number">{{idx}}</div>
                            <svg width="33" height="33" viewBox="0 0 200 200">
                                <g transform="translate(100,100)" stroke="#000" stroke-width="1">
                                    <path d="M-30 30-70 70 				A99 99 0 0 1-70-70 				L-30 -30 				A45 45 0 0 0-30 30Z"
                                        [attr.fill]="intraOral[idx].lef.color" stroke="black" class="t-left" />
                                    <path d="M-30 -30-70-70 				A99 99 0 0 1 70-70 				L30 -30 				A45 45 0 0 0 -30-30Z"
                                        [attr.fill]="intraOral[idx].top.color" stroke="black" class="t-top" />
                                    <path d="M30 -30 70-70 				A99 99 0 0 1 70 70 				L30 30 				A45 45 0 0 0 30 -30Z"
                                        [attr.fill]="intraOral[idx].rig.color" stroke="black" class="t-right" />
                                    <path d="M30 30 70 70 				A99 99 0 0 1-70 70 				L-30 30 				A45 45 0 0 0 30 30Z"
                                        [attr.fill]="intraOral[idx].bot.color" stroke="black" class="t-bottom" />
                                    <circle cx="0" cy="0" r="42" stroke="black" [attr.fill]="intraOral[idx].cen.color"
                                        class="t-center"></circle>
                                </g>
                            </svg>
                        </div>
                    </td>
                    <td>
                        <div class="intraoral-tooth text-center pull-left"
                            *ngFor="let idx of intraOralForm.left_0; let i = index;">
                            <!-- <div class="condition">{{intraOral[idx].legendsStr.join(', ')}}</div> -->
                            <div class="condition">
                                <span [style.color]="tc.cr"
                                    *ngFor="let tc of intraOral[idx].legendsObj">{{tc.cd}}</span>
                            </div>
                            <div class="tooth-number">{{idx}}</div>
                            <svg width="33" height="33" viewBox="0 0 200 200">
                                <g transform="translate(100,100)" stroke="#000" stroke-width="1">
                                    <path d="M-30 30-70 70 				A99 99 0 0 1-70-70 				L-30 -30 				A45 45 0 0 0-30 30Z"
                                        [attr.fill]="intraOral[idx].lef.color" stroke="black" class="t-left" />
                                    <path d="M-30 -30-70-70 				A99 99 0 0 1 70-70 				L30 -30 				A45 45 0 0 0 -30-30Z"
                                        [attr.fill]="intraOral[idx].top.color" stroke="black" class="t-top" />
                                    <path d="M30 -30 70-70 				A99 99 0 0 1 70 70 				L30 30 				A45 45 0 0 0 30 -30Z"
                                        [attr.fill]="intraOral[idx].rig.color" stroke="black" class="t-right" />
                                    <path d="M30 30 70 70 				A99 99 0 0 1-70 70 				L-30 30 				A45 45 0 0 0 30 30Z"
                                        [attr.fill]="intraOral[idx].bot.color" stroke="black" class="t-bottom" />
                                    <circle cx="0" cy="0" r="42" stroke="black" [attr.fill]="intraOral[idx].cen.color"
                                        class="t-center"></circle>
                                </g>
                            </svg>
                        </div>
                    </td>
                </tr>
                <!-- <tr><td colspan="2"> <hr> </td></tr> -->
                <tr class="vbot">
                    <td align="right">
                        <div class="intraoral-tooth text-center pull-right"
                            *ngFor="let idx of intraOralForm.right_1; let i = index;">
                            <!-- <div class="condition">{{intraOral[idx].legendsStr.join(', ')}}</div> -->
                            <div class="condition">
                                <span [style.color]="tc.cr"
                                    *ngFor="let tc of intraOral[idx].legendsObj">{{tc.cd}}</span>
                            </div>
                            <div class="tooth-number">{{idx}}</div>
                            <svg width="33" height="33" viewBox="0 0 200 200">
                                <g transform="translate(100,100)" stroke="#000" stroke-width="1">
                                    <path d="M-30 30-70 70 				A99 99 0 0 1-70-70 				L-30 -30 				A45 45 0 0 0-30 30Z"
                                        [attr.fill]="intraOral[idx].lef.color" stroke="black" class="t-left" />
                                    <path d="M-30 -30-70-70 				A99 99 0 0 1 70-70 				L30 -30 				A45 45 0 0 0 -30-30Z"
                                        [attr.fill]="intraOral[idx].top.color" stroke="black" class="t-top" />
                                    <path d="M30 -30 70-70 				A99 99 0 0 1 70 70 				L30 30 				A45 45 0 0 0 30 -30Z"
                                        [attr.fill]="intraOral[idx].rig.color" stroke="black" class="t-right" />
                                    <path d="M30 30 70 70 				A99 99 0 0 1-70 70 				L-30 30 				A45 45 0 0 0 30 30Z"
                                        [attr.fill]="intraOral[idx].bot.color" stroke="black" class="t-bottom" />
                                    <circle cx="0" cy="0" r="42" stroke="black" [attr.fill]="intraOral[idx].cen.color"
                                        class="t-center"></circle>
                                </g>
                            </svg>
                        </div>
                    </td>
                    <td>
                        <div class="intraoral-tooth text-center pull-left"
                            *ngFor="let idx of intraOralForm.left_1; let i = index;">
                            <div class="condition">
                                <span [style.color]="tc.cr"
                                    *ngFor="let tc of intraOral[idx].legendsObj">{{tc.cd}}</span>
                            </div>
                            <!-- <div class="condition">{{intraOral[idx].legendsStr.join(', ')}}</div> -->
                            <div class="tooth-number">{{idx}}</div>
                            <svg width="33" height="33" viewBox="0 0 200 200">
                                <g transform="translate(100,100)" stroke="#000" stroke-width="1">
                                    <path d="M-30 30-70 70 				A99 99 0 0 1-70-70 				L-30 -30 				A45 45 0 0 0-30 30Z"
                                        [attr.fill]="intraOral[idx].lef.color" stroke="black" class="t-left" />
                                    <path d="M-30 -30-70-70 				A99 99 0 0 1 70-70 				L30 -30 				A45 45 0 0 0 -30-30Z"
                                        [attr.fill]="intraOral[idx].top.color" stroke="black" class="t-top" />
                                    <path d="M30 -30 70-70 				A99 99 0 0 1 70 70 				L30 30 				A45 45 0 0 0 30 -30Z"
                                        [attr.fill]="intraOral[idx].rig.color" stroke="black" class="t-right" />
                                    <path d="M30 30 70 70 				A99 99 0 0 1-70 70 				L-30 30 				A45 45 0 0 0 30 30Z"
                                        [attr.fill]="intraOral[idx].bot.color" stroke="black" class="t-bottom" />
                                    <circle cx="0" cy="0" r="42" stroke="black" [attr.fill]="intraOral[idx].cen.color"
                                        class="t-center"></circle>
                                </g>
                            </svg>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td align="right">
                        <div class="intraoral-tooth text-center pull-right"
                            *ngFor="let idx of intraOralForm.right_2; let i = index;">
                            <svg width="33" height="33" viewBox="0 0 200 200">
                                <g transform="translate(100,100)" stroke="#000" stroke-width="1">
                                    <path d="M-30 30-70 70 				A99 99 0 0 1-70-70 				L-30 -30 				A45 45 0 0 0-30 30Z"
                                        [attr.fill]="intraOral[idx].lef.color" stroke="black" class="t-left" />
                                    <path d="M-30 -30-70-70 				A99 99 0 0 1 70-70 				L30 -30 				A45 45 0 0 0 -30-30Z"
                                        [attr.fill]="intraOral[idx].top.color" stroke="black" class="t-top" />
                                    <path d="M30 -30 70-70 				A99 99 0 0 1 70 70 				L30 30 				A45 45 0 0 0 30 -30Z"
                                        [attr.fill]="intraOral[idx].rig.color" stroke="black" class="t-right" />
                                    <path d="M30 30 70 70 				A99 99 0 0 1-70 70 				L-30 30 				A45 45 0 0 0 30 30Z"
                                        [attr.fill]="intraOral[idx].bot.color" stroke="black" class="t-bottom" />
                                    <circle cx="0" cy="0" r="42" stroke="black" [attr.fill]="intraOral[idx].cen.color"
                                        class="t-center"></circle>
                                </g>
                            </svg>
                            <div class="tooth-number">{{idx}}</div>
                            <div class="condition">
                                <span [style.color]="tc.cr"
                                    *ngFor="let tc of intraOral[idx].legendsObj">{{tc.cd}}</span>
                            </div>
                            <!-- <div class="condition">{{intraOral[idx].legendsStr.join(', ')}}</div> -->
                        </div>
                    </td>
                    <td>
                        <div class="intraoral-tooth text-center pull-left"
                            *ngFor="let idx of intraOralForm.left_2; let i = index;">
                            <svg width="33" height="33" viewBox="0 0 200 200">
                                <g transform="translate(100,100)" stroke="#000" stroke-width="1">
                                    <path d="M-30 30-70 70 				A99 99 0 0 1-70-70 				L-30 -30 				A45 45 0 0 0-30 30Z"
                                        [attr.fill]="intraOral[idx].lef.color" stroke="black" class="t-left" />
                                    <path d="M-30 -30-70-70 				A99 99 0 0 1 70-70 				L30 -30 				A45 45 0 0 0 -30-30Z"
                                        [attr.fill]="intraOral[idx].top.color" stroke="black" class="t-top" />
                                    <path d="M30 -30 70-70 				A99 99 0 0 1 70 70 				L30 30 				A45 45 0 0 0 30 -30Z"
                                        [attr.fill]="intraOral[idx].rig.color" stroke="black" class="t-right" />
                                    <path d="M30 30 70 70 				A99 99 0 0 1-70 70 				L-30 30 				A45 45 0 0 0 30 30Z"
                                        [attr.fill]="intraOral[idx].bot.color" stroke="black" class="t-bottom" />
                                    <circle cx="0" cy="0" r="42" stroke="black" [attr.fill]="intraOral[idx].cen.color"
                                        class="t-center"></circle>
                                </g>
                            </svg>
                            <div class="tooth-number">{{idx}}</div>
                            <div class="condition">
                                <span [style.color]="tc.cr"
                                    *ngFor="let tc of intraOral[idx].legendsObj">{{tc.cd}}</span>
                            </div>
                            <!-- <div class="condition">{{intraOral[idx].legendsStr.join(', ')}}</div> -->
                        </div>
                    </td>
                </tr>
                <tr>
                    <td align="right">
                        <div class="intraoral-tooth text-center pull-right"
                            *ngFor="let idx of intraOralForm.right_3; let i = index;">
                            <svg width="33" height="33" viewBox="0 0 200 200">
                                <g transform="translate(100,100)" stroke="#000" stroke-width="1">
                                    <path d="M-30 30-70 70 				A99 99 0 0 1-70-70 				L-30 -30 				A45 45 0 0 0-30 30Z"
                                        [attr.fill]="intraOral[idx].lef.color" stroke="black" class="t-left" />
                                    <path d="M-30 -30-70-70 				A99 99 0 0 1 70-70 				L30 -30 				A45 45 0 0 0 -30-30Z"
                                        [attr.fill]="intraOral[idx].top.color" stroke="black" class="t-top" />
                                    <path d="M30 -30 70-70 				A99 99 0 0 1 70 70 				L30 30 				A45 45 0 0 0 30 -30Z"
                                        [attr.fill]="intraOral[idx].rig.color" stroke="black" class="t-right" />
                                    <path d="M30 30 70 70 				A99 99 0 0 1-70 70 				L-30 30 				A45 45 0 0 0 30 30Z"
                                        [attr.fill]="intraOral[idx].bot.color" stroke="black" class="t-bottom" />
                                    <circle cx="0" cy="0" r="42" stroke="black" [attr.fill]="intraOral[idx].cen.color"
                                        class="t-center"></circle>
                                </g>
                            </svg>
                            <div class="tooth-number">{{idx}}</div>
                            <div class="condition">
                                <span [style.color]="tc.cr"
                                    *ngFor="let tc of intraOral[idx].legendsObj">{{tc.cd}}</span>
                            </div>
                            <!-- <div class="condition">{{intraOral[idx].legendsStr.join(', ')}}</div> -->
                        </div>
                    </td>
                    <td>
                        <div class="intraoral-tooth text-center pull-left"
                            *ngFor="let idx of intraOralForm.left_3; let i = index;">
                            <svg width="33" height="33" viewBox="0 0 200 200">
                                <g transform="translate(100,100)" stroke="#000" stroke-width="1">
                                    <path d="M-30 30-70 70 				A99 99 0 0 1-70-70 				L-30 -30 				A45 45 0 0 0-30 30Z"
                                        [attr.fill]="intraOral[idx].lef.color" stroke="black" class="t-left" />
                                    <path d="M-30 -30-70-70 				A99 99 0 0 1 70-70 				L30 -30 				A45 45 0 0 0 -30-30Z"
                                        [attr.fill]="intraOral[idx].top.color" stroke="black" class="t-top" />
                                    <path d="M30 -30 70-70 				A99 99 0 0 1 70 70 				L30 30 				A45 45 0 0 0 30 -30Z"
                                        [attr.fill]="intraOral[idx].rig.color" stroke="black" class="t-right" />
                                    <path d="M30 30 70 70 				A99 99 0 0 1-70 70 				L-30 30 				A45 45 0 0 0 30 30Z"
                                        [attr.fill]="intraOral[idx].bot.color" stroke="black" class="t-bottom" />
                                    <circle cx="0" cy="0" r="42" stroke="black" [attr.fill]="intraOral[idx].cen.color"
                                        class="t-center"></circle>
                                </g>
                            </svg>
                            <div class="tooth-number">{{idx}}</div>
                            <div class="condition">
                                <span [style.color]="tc.cr"
                                    *ngFor="let tc of intraOral[idx].legendsObj">{{tc.cd}}</span>
                            </div>
                            <!-- <div class="condition">{{intraOral[idx].legendsStr.join(', ')}}</div> -->
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table">
            <tbody>
                <tr>
                    <td *ngIf="formPoat.periodontal.length>0">
                        <h5>Predental Screening</h5>
                        <span class="text-capitalize"> {{formPoat.periodontal.join(', ')}}</span>
                    </td>
                    <td *ngIf="formPoat.occlusion.length>0">
                        <h5>Occlusion</h5>
                        <span class="text-capitalize"> {{formPoat.occlusion.join(', ')}}</span>
                    </td>
                    <td *ngIf="formPoat.appliance.length>0">
                        <h5>Appliance</h5>
                        <span class="text-capitalize"> {{formPoat.appliance.join(', ')}}</span>
                    </td>
                    <td *ngIf="formPoat.tmd.length>0">
                        <h5>TMD</h5>
                        <span class="text-capitalize"> {{formPoat.tmd.join(', ')}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="form-group" *ngIf="recall.procedureSummary && recall.procedureSummary!=''">
            <label for="">Recall Summary</label>
            <h4 class="text-label">{{recall.procedureSummary}}</h4>
        </div>

        <div class="form-group legend-texts" *ngIf="legendTexts && legendTexts.length>0">
            <h4 for=""> <strong>Legends:</strong> </h4>
            <div class="legends">
                <div class="legend" *ngFor="let lt of legendTexts">
                    <span class="code">{{lt.c}}</span>
                    <span class="text">{{lt.t}}</span>
                </div>
            </div>
        </div>

    </section>

      <div class="loading-indicator text-center" *ngIf="loading">
        <div class="spinner">
          <h1><i class="fas fa-spinner fa-spin"></i></h1>
        </div>
      </div>

      <div class="images" *ngIf="!loading">
        <div class="img-files" *ngIf="recallFiles && recallFiles.length > 0; else noFileRecall">
          <div *ngFor="let file of recallFiles">
            <div *ngIf="file.filename; else noImage">
                <img class="img-fluid" [src]="file.filename.startsWith('data:') ? file.filename : base_url + '/file?f=' + file.filename"/>
            </div>
            <ng-template #noImage>
              <p>No image set in Recall</p>
            </ng-template>
          </div>
        </div>
        <ng-template #noFileRecall>
          <div *ngIf="(!recallFiles || recallFiles.length === 0)" class="text-center">
            <p>No Recall Files</p>
          </div>
        </ng-template>
      </div>
      <br>

</div>
