import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import printJS from 'print-js'
import { Associate, Patient, PatientBill } from '@app/shared/models';
import { Router } from '@angular/router';
import { Location, DatePipe, CurrencyPipe } from '@angular/common';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssociatesService } from '../api/associates.service';
import { AuthService } from '@app/shared/auth/auth.service';
import {
  PRINT_TYPE_BILL,
  PRINT_TYPE_PRESCRIPTION,
  PRINT_TYPE_TREATMENT_PLAN
} from '@app/shared/constants/settings.constants';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  template: string = 'half-letter';
  templatePage: string;

  htmlToPrint = new BehaviorSubject('-');
  showRx = new BehaviorSubject(false);
  printType: string = 'prescription';
  activePatient = new BehaviorSubject(<Patient>{});
  activeAssociate = new BehaviorSubject({});
  activePrint = new BehaviorSubject(<any>{});
  toPrintData = new BehaviorSubject(<any>{});
  recordDate = new BehaviorSubject({});
  associateId: number = 0;

  isPrinting = false;
  hideSignatures = new BehaviorSubject(<boolean>false);

  public contentReady: boolean = true;

  constructor(
    private router: Router,
    private location: Location,
    private ngxSpinnerSvc: NgxSpinnerService,
    private assocSvc: AssociatesService,
    private authSvc: AuthService
  ) {}

  printDocument(template: string) {
    this.template = template;
    if (template && template == 'letter') {
      this.templatePage = 'genericPrint';
    }
    setTimeout(() => {
      this.isPrinting = true;
      // this.router.navigateByUrl('(print:' + template + ')');
    }, 10);
  }

  onDataReady() {
    if (this.contentReady) {
      this.print();
      return;
    }

    setTimeout(() => {
        this.onDataReady();
      }, 100);
  }

  print() {
    this.ngxSpinnerSvc.hide('printing');
    const ua = navigator.userAgent;

    // Define device types
    const isSmallDevice = /(iPhone|iPad|iPod|CriOS)/i.test(ua);
    const isDesktop = /(Windows|Macintosh|Chrome|Safari)/i.test(ua);
    const isUnsupportedDevice = /(Android|BlackBerry|IEMobile|Opera Mini|IPod)/i.test(ua);

    // If device is unsupported, show alert and return early
    if (isUnsupportedDevice) {
        swal(
            'Device Not Supported',
            'Printing is not supported on this device. Please use an alternative device or contact support for assistance.',
            'error'
        );
        this.refreshSetFlags();
        return;
    }

    // Printing for small devices (iPhone/iPad/CriOS)
    if (isSmallDevice) {
        this.printExecuteSmallDevice();
        return;
    }

    // Printing for desktop devices (Windows/Mac)
    if (isDesktop) {
        setTimeout(() => {
            window.print();

        }, 1);
        this.refreshSetFlags();
        return;
    }

    // Default case: unsupported devices not caught by `isUnsupportedDevice`
    swal(
        'Device Not Supported',
        'Printing is not supported on this device. Please use an alternative device or contact support for assistance.',
        'error'
    );
    this.refreshSetFlags();
  }

  printData(htmlString: string, template?: string) {
    this.ngxSpinnerSvc.show('printing');
    if (!template) {
      template = 'half-letter';
    }
    this.htmlToPrint.next(htmlString);
    setTimeout(() => {
      this.printDocument(template);
    }, 1);
  }

  printToLetter(page: string) {
    this.ngxSpinnerSvc.show('printing');
    this.template = 'letter';
    this.templatePage = page;
    setTimeout(() => {
      this.isPrinting = true;
      // this.router.navigateByUrl('(print:letter/' + page + ')');
    }, 10);
    // }, 100)
  }

  printTreatmentPlan(printOptions: any) {
    const plan: any = printOptions.plan;
    const patient: Patient = printOptions.patient;
    const useFixedAmountDiscount: any = printOptions.useFixedAmountDiscount;
    const template: string = printOptions.template ? printOptions.template : 'half-letter';

    const cPipe: CurrencyPipe = new CurrencyPipe('en');
    this.associateId = plan.associateId;
    // set patient
    this.activePatient.next(patient);
    // this.activeAssociate.next(plan.associate);
    this.activePrint.next(PRINT_TYPE_TREATMENT_PLAN);

    let totalCost = 0;
    let discountP = 0;
    let discountAmount = parseFloat(plan.discountAmount);
    let netCost = 0;
    let colspan: number = 2;
    const showRowPrice: boolean = plan.showRowPrice;
    let plans: any = [];
    let idCounter = 0; // Initialize ID counter

    plan.details.forEach((tpd: any, index: number) => {
      const apptIndex = index; // Use iteration index as the index
      if (!plans[apptIndex]) {
        plans[apptIndex] = {
          id: idCounter++,
          total_cost: 0,
          discount_percent: parseFloat(plan.discountPercent),
          appt_no: tpd.apptNo,
          details: []
        };
      }
      plans[apptIndex].details.push(tpd);
      plans[apptIndex].total_cost += parseFloat(tpd.cost);
    });
    let html: string = '<h3 align="center" class="page-title">Treatment Plan</h3><div class="print-treatment-plan">';
    html += '<table class="table table-bordered" >';
    // html += '<thead><tr><th width="70">Tooth #</th><th>Procedure</th></tr></thead><tbody>';
    html += '<thead><tr><th width="60">Appt </th><th width="70">Tooth #</th><th>Procedure</th>';
    if (showRowPrice) {
      html += '<th>Est. Cost </th>';
      colspan += 1;
    } else {
      html += '<th>Est. Cost </th>';
      colspan += 1;
    }
    html += '</tr></thead><tbody>';
    let prevApptNo = '';
    let subtotal = 0; // Initialize subtotal for each appt_no
    plans.forEach((plan, idx) => {
      if (plan.details.length > 0) {
        if (plan.appt_no !== prevApptNo) {
          if (prevApptNo !== '') {
            html +=
              '<tr><td colspan="' +
              colspan +
              '" align="right"><strong>Subtotal: </strong></td><td align="right"><strong>' +
              cPipe.transform(subtotal.toFixed(2), ' ') +
              '</strong></td></tr>';
            html += '<tr><td colspan="' + colspan + '" align="right"> </td><td> </td></tr>';
          }

          prevApptNo = plan.appt_no;
          subtotal = 0; // Reset subtotal for the new appt_no
          html += '<tr><td rowspan="' + plan.details.length + '">' + plan.appt_no + '</td>';
        } else {
          html += '<tr><td></td>';
        }

        let detail = plan.details[0];
        html += '<td>';
        for (let i = 0; i < detail.toothNo.length; i++) {
          html += '<span>' + detail.toothNo[i] + '</span>';
        }
        html += '</td>';
        html += '<td>' + detail.planProcedure + '</td>';
        if (showRowPrice) {
          html += '<td align="right">' + cPipe.transform(parseFloat(detail.cost).toFixed(2), ' ') + '</td>';
        } else {
          html += '<td align="center"> - </td>';
        }
        subtotal += parseFloat(detail.cost); // Add to subtotal
        html += '</tr>';
        totalCost += parseFloat(plan.total_cost);
        netCost += parseFloat(plan.net_cost);
        discountP = parseFloat(plan.discount_percent);

        for (let x = 1; x < plan.details.length; x++) {
          detail = plan.details[x];
          html += '<tr>';
          html += '<td>';
          for (let i = 0; i < detail.toothNo.length; i++) {
            html += '<span>' + detail.toothNo[i] + '</span>';
          }
          html += '</td>';
          html += '<td>' + detail.planProcedure + '</td>';
          if (showRowPrice) {
            html += '<td align="right">' + cPipe.transform(parseFloat(detail.cost).toFixed(2), ' ') + '</td>';
          } else {
            html += '<td align="center"> - </td>';
          }
          subtotal += parseFloat(detail.cost); // Add to subtotal
          html += '</tr>';
        }
      }
    });
    // Add the final subtotal row
    if (prevApptNo !== '') {
      html +=
        '<tr><td colspan="' +
        colspan +
        '" align="right"><strong>Subtotal: </strong></td><td align="right"><strong>' +
        cPipe.transform(subtotal.toFixed(2), ' ') +
        '</strong></td></tr>';
      html += '<tr><td colspan="' + colspan + '" align="right"> </td><td> </td></tr>';
    }
    if (useFixedAmountDiscount) {
      netCost = totalCost - discountAmount;
    } else {
      netCost = totalCost - totalCost * (discountP / 100);
    }
    html += '</tbody>';
    html += '<tfoot><tr><td colspan="' + (colspan + 1) + '">';
    html +=
      '<div align="right" style="display:block;padding:5px;"> <strong>Estimated Cost: ' +
      cPipe.transform(totalCost, ' ') +
      '</strong></div>';
    if (discountP > 0) {
      if (useFixedAmountDiscount) {
        html +=
          '<div align="right" style="display:block;padding:5px;"><strong>Discount Amount: ' +
          cPipe.transform(discountAmount, ' ') +
          '</strong></div>';
      } else {
        html +=
          '<div align="right" style="display:block;padding:5px;"><strong>Discount (%): ' +
          cPipe.transform(discountP, ' ') +
          '</strong></div>';
      }
      html +=
        '<div align="right" style="display:block;padding:5px;"><strong>Net Estimated Cost: ' +
        cPipe.transform(netCost, ' ') +
        '</strong></div>';
    }
    html += '</td></tr> </tfoot></table>';
    html +=
      '<div> <span style="color:red;" class="text-danger danger">**Prices are subject to change without prior notice.</span> </div>';
    html += '<br><div><b><h3>Remarks/Notes</h3></b><br><span >' + plan.details[0].remarks + '</span></div>';
    html += '</div>';

    return this.printData(html, template);
  }

  printBill(bill: any, billDetails: any, patient: Patient) {
    let totalPaid: number = 0;
    billDetails.payments.forEach((p: any) => {
      totalPaid += Number(p.paid_amount);
    });
    let balanceAmount: number = bill.netAmount - totalPaid;
    if (balanceAmount < 0) {
      balanceAmount = 0;
    }

    this.activeAssociate.next(bill.associate);
    this.activePrint.next(PRINT_TYPE_BILL);
    this.activePatient.next(patient);
    // set bill details
    let html: string = '<div class="print-bill">';
    html +=
      '<h3 align="center" class="page-title">Patient Bill on ' +
      new DatePipe('en').transform(bill.billDt, 'mediumDate') +
      '</h3>';
    html += '<table class="table table-bordered table-striped bill-print">';
    html +=
      '<thead><tr><th>Service/Procedure</th><th>Teeth</th><th>Qty</th><th>Base Amount</th><th align="right" class="text-right">Total Amount</th></tr></thead><tbody>';
    billDetails.details.forEach((bd) => {
      html += '<tr>';
      html += '<td>' + bd.service_type + '</td>';
      html += '<td>' + bd.item + '</td>';
      html += '<td>' + bd.qty + '</td>';
      html += '<td align="right">' + new CurrencyPipe('en').transform(bd.unit_price, 'P') + '</td>';
      html += '<td align="right">' + new CurrencyPipe('en').transform(bd.line_amount, 'P') + '</td>';
      html += '</tr>';
    });
    html += '</tbody><tfoot>';
    html +=
      '<tr><td colspan="4">SubTotal</td><td align="right">' +
      new CurrencyPipe('en').transform(bill.grossAmount, 'P') +
      '</td></tr>';
    if (bill.discountAmount > 0) {
      html +=
        '<tr><td colspan="4">Discount</td><td align="right">' +
        new CurrencyPipe('en').transform(bill.discountAmount, 'P') +
        '</td></tr>';
    }
    if (totalPaid > 0) {
      html +=
        '<tr><td colspan="4">Paid Amt</td><td align="right" class="paid">' +
        new CurrencyPipe('en').transform(totalPaid, 'P') +
        '</td></tr>';
    }
    html +=
      '<tr><td colspan="4">Balance</td><td align="right" class="balance">' +
      new CurrencyPipe('en').transform(balanceAmount, 'P') +
      '</td></tr>';
    html += '</tfoot></table>';

    if (billDetails.payments && billDetails.payments.length > 0) {
      html += '<h4 align="left">Payments</h4>';
      html += '<table class="table table-bordered table-striped bill-print">';
      html +=
        '<thead><tr><th width="100">Date</th><th>Details</th><th align="right" class="text-right">Total</th></tr></thead><tbody>';

      billDetails.payments.forEach((p) => {
        html += '<tr>';
        html += '<td>' + new DatePipe('en').transform(p.payment_dt, 'mediumDate') + '</td>';
        html += '<td>';

        const pdetails: any = p.payment_details;
        if (pdetails && pdetails.length > 0) {
          pdetails.forEach((pd) => {
            html += '<div><span class="text-capitalize">' + pd.payment_type + '</span> &nbsp;&nbsp;';
            html += '<span align="right">' + new CurrencyPipe('en').transform(pd.paid_amount, 'P') + '</span></div>';
          });
        }
        if (p.remarks.trim().length > 1) {
          html += '<div>(Remarks: ' + p.remarks + ')</div>';
        }

        html += '</td>';
        html += '<td align="right">' + new CurrencyPipe('en').transform(p.paid_amount, 'P') + '</td>';
        html += '</tr>';
      });

      html += '</tbody><tfoot>';
      html += '</tfoot></table>';
    }
    if (billDetails.details.length <= 2) {
      html += '<br><br>';
    }
    html += '</div>';

    return this.printData(html);
  }

  printPrescription(medicines: any[], patient: Patient, remarks?: string, associate?: any) {
    // set patient
    this.activePatient.next(patient);
    this.activeAssociate.next(associate);
    this.activePrint.next(PRINT_TYPE_PRESCRIPTION);

    this.showRx.next(true);
    // set prescription details
    let html: string = '<div class="print-prescription">';
    medicines.forEach((med, k) => {
      html += '<div class="pdetail">';
      // html += '<div class="quantity"> x ' + med.quantity + '</div>';
      html +=
        '<div class="medicine"><span class="med">' +
        (k + 1).toString() +
        '.) ' +
        med.medicine +
        '</span><span class="quantity"> # ' +
        med.quantity +
        '</span></div>';
      html += '<div class="dosage"><i>Sig:</i> ' + med.dosage + '</div>';
      html += '</div>';
    });
    if (remarks && remarks.trim() != '') {
      html += '<div><br>';
      html += remarks;
      html += '</div>';
    }
    if (medicines.length === 1) {
      html += '<br><br><br><br><br><br>';
    } else if (medicines.length === 2) {
      html += '<br><br><br><br>';
    }
    html += '</div>';
    return this.printData(html);
  }

  private refreshSetFlags(){
    setTimeout(() => {
      this.isPrinting = false;
      this.showRx.next(false);
      this.hideSignatures.next(false);
      this.contentReady = true;
      this.htmlToPrint.next('-');
      this.recordDate.next({});
      // this.location.back();
    }, 1000);
  }

  private createPrintHTML(htmlContent, pageSize, orientation, width, height) {
    const version = new Date().getTime();
    return `<html>
            <head>
              <title>Print</title>
              <link rel="stylesheet" type="text/css" href="/assets/css/print-inline.css?v=${version}">
              <style>
                body{ overflow-x: hidden; }
                body div{ display:none; }
                @page{ size: ${pageSize} ${orientation}; }
                @media print {
                  body{ display:block; }
                  body div{ display:block; }
                  body,html { height: 100%;margin: 0; }
                  .print-container { display: flex; flex-direction: column;}
                  .head-details{ text-align: center;}
                  .header { flex: 0 0 auto; }
                  .content { flex: 1 1 auto; overflow: auto; }
                  .patient-details { display: flex; }
                  .flex-left { flex: 1; }
                  .flex-right { flex-shrink: 0;padding-right: 10px; }
                  .footer { page-break-before: avoid; page-break-inside: avoid; display: flex; flex: 0 0 auto;}
                  .signature { display: block; max-width: 150px !important; }
                  .signature img { max-width: 150px; }
                  .text-danger, .danger { color: red; }
                }
                .page-size-wrapper { width: ${width};  height: ${height}; }
              </style>
            </head>
            <body>
              <div class="page-size-wrapper">${htmlContent}</div>
            </body>
          </html>`;
  }

  private printExecuteSmallDevice(){
    setTimeout(() => {
      const ua = navigator.userAgent;
      const isSmallDevice = /(iPhone|iPad|iPod|CriOS)/i.test(ua);
      const htmlContent = document.querySelector('.print-container.ql-snow').outerHTML;

      // Open a new window or tab
      const printWindow = window.open('', '_blank');
      const pageSize = this.template === 'half-letter' ? '5.5in 8.5in' : '8.5in 11in';
      const orientation = this.template === 'half-letter' ? 'landscape' : 'portrait';
      const [width, height] = pageSize.split(' ');

      // Write the HTML content to the new window or tab
      printWindow.document.write(this.createPrintHTML(htmlContent, pageSize, orientation, width, height));
      printWindow.document.close();

      // Wait for all content to load before printing
      printWindow.onload = () => {
        if(isSmallDevice){
          if(typeof printWindow.print !== 'function'){
            let printButton = printWindow.document.createElement('button');
            printButton.textContent = 'Print';
            printButton.onclick = () => printWindow.print();
            printWindow.document.body.appendChild(printButton);
          }else{
            printWindow.print();
          }
        }else{
          printWindow.print();
        }
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    }, 1);
    this.refreshSetFlags();

  }
}
