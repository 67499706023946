import { Component, OnInit, OnDestroy } from '@angular/core';
import { Patient } from '@app/shared/models';
import { PrintService } from '@app/shared/services/helpers/print.service';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-ppds-informed-consent-waiver',
    templateUrl: './ppds-informed-consent-waiver.component.html',
    styleUrls: ['./ppds-informed-consent-waiver.component.scss']
})
export class PpdsInformedConsentWaiverComponent implements OnInit, OnDestroy {

    base_url: string = environment.url;
    patient: Patient;
    assessment: any;
    assessJSON: any;
    assessmentItems: any;

    printSub: Subscription;
    patientSub: Subscription;
    imgQueueLoading: number = 0;

    constructor(
        public printSvc: PrintService
    ) { }

    ngOnInit() {

        this.printSvc.contentReady = false;
        this.printSvc.hideSignatures.next(true);

        this.patientSub = this.printSvc.activePatient.subscribe(
            (patient: any) => {
                this.patient = patient;
            }
        )

        this.printSub = this.printSvc.toPrintData.subscribe(
            (printData: any) => {
                if (printData && printData.assessment) {
                    this.assessment = printData.assessment;
                    this.assessJSON = JSON.parse(this.assessment.consent_json);
                   
                    this.loadSignature(this.assessment.signature_url);
                    if (this.assessment.guardian_sig_url) {
                        this.loadSignature(this.assessment.guardian_sig_url);
                    }

                }
            }
        );

    }

    ngOnDestroy() {
        this.printSub.unsubscribe();
        this.patientSub.unsubscribe();
    }

    print() {
        if (this.imgQueueLoading <= 0) {
            this.printSvc.contentReady = true;
        }
    }
    // load signature
    loadSignature(src: string) {
        this.imgQueueLoading += 1;
        var img = new Image();
        img.onload = (e) => {
            this.imgQueueLoading -= 1;
            setTimeout(() => { this.print(); }, 10)
            // this.printSvc.contentReady = true;
        }
        img.onerror = (e) => {
            this.imgQueueLoading -= 1;
            setTimeout(() => { this.print(); }, 10)
            // this.printSvc.contentReady = true;
        }
        img.src = src;
    }

}
