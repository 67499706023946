import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { tap, map, catchError, switchMap, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { CrudListResponses } from '@app/shared/models';
import swal from 'sweetalert2';
import { GetAPIResponse } from '@app/shared/models/crud-responses';
import { ALL_RECORD, PAGINATED } from '@app/shared/constants/RecordTypeConstant';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  url: string = environment.mstfUrl + '/records';
  records: Observable<[]>;
  private readonly _record = new BehaviorSubject<GetAPIResponse>({});
  public record$ = this._record.asObservable();
  recordsArray: any[] = [];
  private _records;
  params: any = {
    per_page: 100,
    limit: 100,
    page: 1,
    total: 0,
    sortField: 'updated_dt',
    sortDirection: 'asc',
    recordType: PAGINATED
  };

  constructor(public http: HttpClient) {
    // this.records.asObservable();
    // this._records.asObservable();
    // this.getInitRecords().subscribe();
  }
  setParams(params: any) {
    this.params = params;
  }
  refreshList() {
    this._record.next(null);
    this.getInitialRecords(this.params).subscribe();
  }
  getInitialRecords(params?: any) {
    return this.http.get(`${this.url}`, { params: params }).pipe(
      tap((data: GetAPIResponse) => {
        this._record.next(data);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  // deprecated endpoint
  getInitRecords(): Observable<[]> {
    return this.http.get(`${this.url}`).pipe(
      map((data: CrudListResponses) => <[]>data.rows),
      tap((data) => {
        this.records = of(data);
        this._records = data;
        this.recordsArray = data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  // deprecate endpoint
  getAll(): Observable<[]> {
    if (this.records) {
      return this.records;
    } else {
      return this.getInitRecords();
    }
  }
  // another new endpoint for getting all records without pagination
  getAllRecord(): Observable<[]> {
    let params = this.params;
    params.recordType = ALL_RECORD;
    if (this.records) {
      return this.records;
    } else {
      return this.http.get(`${this.url}`, { params: params }).pipe(
        tap((data: any) => {
          this.records = of(data);
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }
  getAllRecords(): Observable<GetAPIResponse> {
    if (Object.keys(this._record.getValue()).length > 0) {
      return this._record;
    } else {
      return this.getInitialRecords(this.params);
    }
  }
  // deprecate endpoint
  get(id: number): Observable<{}> {
    if (this._records && this._records.length > 0) {
      return of(this._records.find((row) => row.id == id));
    } else {
      return this.http.get(`${this.url}/${id}`).pipe(
        tap(
          (data) => {
            return of(data);
          },
          catchError((error) => {
            return throwError(error);
          })
        )
      );
    }
  }
  getRecord(id: number): Observable<{}> {
    if (this._records && this._records.length > 0) {
      return of(this._records.find((row) => row.id == id));
    } else {
      return this.http.get(`${this.url}/${id}`).pipe(
        tap(
          (data) => {
            return of(data);
          },
          catchError((error) => {
            return throwError(error);
          })
        )
      );
    }
  }
  createRecord(data: any): Observable<{}> {
    return this.http.post(`${this.url}`, data).pipe(
      tap(
        (res: any) => {
          if (res) {
            let record: any = this._record.getValue();
            record.data.push(res);
            record.total += 1;
            this._record.next(record);
          }
        },
        (error) => {
          swal({
            title: 'Save error!',
            text: error.error.error ? error.error.error : error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }
  // deprecated endpoint
  create(data: any): Observable<{}> {
    return this.http.post(`${this.url}`, data).pipe(
      tap(
        (res: any) => {
          data.id = res.id;
          if (res.account_id) {
            data.account_id = res.account_id;
          }
          if (res.status) {
            data.status = res.status;
          }
          this._records.push(<{}>data);
          return of(<{}>data);
        },
        (error) => {
          swal({
            title: 'Save error!',
            text: error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }
  updateRecord(id: number, data: any): Observable<{}> {
    return this.http.put(`${this.url}/${id}`, data).pipe(
      tap(
        (res: any) => {
          let record: any = this._record.getValue();
          const idx = record.data.findIndex((row) => {
            return row.id == id;
          });
          record.data.splice(idx, 1, res);
          this._record.next(record);
        },
        (error) => {
          swal({
            title: 'Update error!',
            text: error.error.error ? error.error.error : error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }
  //deprecated endpoint
  update(id: number, data: any): Observable<{}> {
    return this.http.post(`${this.url}/${id}`, data).pipe(
      tap(
        (res: any) => {
          let records = this._records.find((row) => row.id == id);
          records = <{}>Object.assign(records, data);
        },
        (error) => {
          swal({
            title: 'Update error!',
            text: error.error.message,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
          return of(null);
        }
      )
    );
  }
  // deprecated endpoint update end

  deleteRecord(id: number): Observable<boolean> {
    return this.http.delete(`${this.url}/${id}`).pipe(
      tap((data) => {
        let record: any = this._record.getValue();
        const idx = record.data.findIndex((row) => {
          return row.id == id;
        });
        record.data.splice(idx, 1);
        record.total -= 1;
        this._record.next(record);
      }),
      map(() => true),
      catchError((error) => {
        swal({
          title: 'Delete error!',
          text: error.error.message,
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6'
        });
        return throwError(error);
      })
    );
  }
  updateStatus(id: number, data: any): Observable<boolean> {
    return this.http.put(`${this.url}/${id}/status`, data).pipe(
      tap((data) => {
        let record: any = this._record.getValue();
        const idx = record.data.findIndex((row) => {
          return row.id == id;
        });
        record.data.splice(idx, 1);
        record.total -= 1;
        this._record.next(record);
      }),
      map(() => true),
      catchError((error) => {
        swal({
          title: 'Delete error!',
          text: error.error.message,
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6'
        });
        return throwError(error);
      })
    );
  }

  //deprecated endpoint delete
  delete(id: number): Observable<boolean> {
    return this.http.delete(`${this.url}/${id}`).pipe(
      tap((data) => {
        let records = this._records.find((row) => row.id == id);
        this._records.splice(this._records.indexOf(records), 1);
      }),
      map(() => true),
      catchError((error) => {
        swal({
          title: 'Delete error!',
          text: error.error.message,
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6'
        });
        return throwError(error);
      })
    );
  }
  // deprecated endpoint delete end
}
