export const CACHED_PRINT_LOGO_KEY = 'da-print-logo';
export const CACHED_DIGITAL_SIGNATURE_KEY = 'da-perma';
export const DEFAULT_HEADER_LOGO_WIDTH = 300;
export const PRINT_HEADER_FILES_KEY = 'print_header_files';
export const PRINT_HEADER_KEY = 'print_header';
export const USE_DIGITAL_SIGNATURE_KEY = 'use_digital_signature';
export const DIGITAL_SIGNATURE_KEY = 'digital_signature';
export const USE_DIGITAL_CLINIC_PRINT_HEADER = 'use_digital_clinic_print_header';
export const PRINT_HEADER_PER_CLINIC_KEY = 'print_header_per_clinic';
export const CACHED_PRINT_HEADER_PER_CLINIC = 'print-header-per-clinic';
export const SHOW_ADMIN_VIRTUAL_TOUR = 1;
export const NOT_SHOW_ADMIN_VIRTUAL_TOUR = 0;

export const ALLOW_STAFF_PRINT = '1';
export const PRINT_TYPE_BILL = 'bill';
export const PRINT_TYPE_PRESCRIPTION = 'prescription';
export const PRINT_TYPE_CHART = 'chart';
export const PRINT_TYPE_CERTIFICATE = 'certificate';
export const PRINT_TYPE_TREATMENT_PLAN = 'treatment_plan';
export const DEVICE_ID_KEY = 'deviceId';
export const APPOINTMENT_FILTER = 'appointment_filter';
export interface CachedFileType {
  filename: string;
  width?: number;
}
