import { Component, OnInit, OnDestroy } from '@angular/core';
import { Patient } from '@app/shared/models';
import { PrintService } from '@app/shared/services/helpers/print.service';
import { ConsentService } from '@app/core/patients/consents/services/consent.service';
import { environment } from '@env/environment';
import { forkJoin, of, Subscription } from 'rxjs';
import { FilesService } from '@app/shared/services/api/files.service';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'app-print-covid-doh-assessment',
    templateUrl: './print-covid-doh-assessment.component.html',
    styleUrls: ['./print-covid-doh-assessment.component.scss']
})
export class PrintCovidDohAssessmentComponent implements OnInit, OnDestroy {

    base_url: string = environment.url;
    patient: Patient;
    assessment: any;
    assessmentItems: any;
    assessJSON: any;

    printSub: Subscription;
    patientSub: Subscription;

    imgQueueLoading: number = 0;
    signatureUrl: string = null;

    constructor(
        public printSvc: PrintService,
        public consentSvc: ConsentService,
        public filesService: FilesService
    ) { }

    ngOnInit() {

        this.printSvc.contentReady = false;
        this.printSvc.hideSignatures.next(true);

        this.patientSub = this.printSvc.activePatient.subscribe(
            (patient: any) => {
                this.patient = patient;
            }
        )

        this.printSub = this.printSvc.toPrintData.subscribe(
            (printData: any) => {
                if (printData && printData.assessment) {
                    this.assessment = printData.assessment;
                    this.assessJSON = JSON.parse(this.assessment.consentJson);
                    this.assessment.assessment_json = JSON.parse(this.assessment.consentJson);
                    let signatureObservable = this.filesService.getFilenameDataUri(this.assessment.signatureUrl).pipe(
                        catchError(error => {
                            return of(null);
                        })
                    );
                    forkJoin({
                        signatureData: signatureObservable
                    }).subscribe((results) => {
                        this.signatureUrl = results.signatureData.uri;
                        this.loadSignature(results.signatureData.uri);
                    });
                }
            }
        );

    }

    ngOnDestroy() {
        this.printSub.unsubscribe();
        this.patientSub.unsubscribe();
    }

    // load signature
    loadSignature(src: string) {
        var img = new Image();
        img.onload = (e) => {
            this.imgQueueLoading -= 1;
            if (this.imgQueueLoading <= 0) {
                setTimeout(() => {
                    this.printSvc.contentReady = true;
                }, 100);
            }
        };
        img.onerror = (e) => {
            this.imgQueueLoading -= 1;
            if (this.imgQueueLoading <= 0) {
                setTimeout(() => {
                    this.printSvc.contentReady = true;
                }, 100);
            }
        };
        img.src = src;
    }

}
